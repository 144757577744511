import React, { useState, useEffect } from "react";
import Base from "../Config/Base";
// import RP4 from '../../Assests/Images/Crimson Anthurium.jpg';
// import RP2 from '../../Assests/Images/Purple Majesty.png';
// import RP3 from '../../Assests/Images/Gooey Chocolate Cake.png';
// import RP1 from '../../Assests/Images/Personalized Anniversary.png';
import { useParams } from 'react-router-dom';
import { BASE_API_URL } from "../Config/Config";
import axios from "axios";
import AddToCart from "../Cart/AddCart1";
import UserIcon from './usericon.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import AddToFavorite from "../Favorites/AddFavorite";
import SignIn from "../Auth/Signin";
import { Rating } from 'react-simple-star-rating'
import ReactImageMagnify from 'react-image-magnify';

export default function ProductDetails () {

    const { ProductId } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [ProductId]);
    
    const [userData, setUserData] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [productRating, setProductRating] = useState([]);
    const [userProductRating, setUserProductRating] = useState([]);
    const [recomendedProducts, setRecomendedProducts] = useState([]);
    const [productFAQs, setProductFAQs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mainImage, setMainImage] = useState('');
    const [selectedCartProdId, setSelectedCartProdId] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [checkPincode, setCheckPincode] = useState('');
    const [checkStatus, setCheckStatus] = useState('');
    const [checkError, setCheckError] = useState('');
    const [cartErrorMessage, setCartErrorMessage] = useState(null);
    const [selectedFavProdId, setSelectedFavProdId] = useState('');
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState("");
    const [images, setImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [activeTab, setActiveTab] = useState('description');
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showFullExtraDescription, setShowFullExtraDescription] = useState(false);
    
    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    const handleThumbnailClick = (src) => {
        setMainImage(src);
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const formData = new FormData();
                formData.append("id", ProductId);

                const response = await axios.post(`${BASE_API_URL}get_products`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setProductDetails(response.data.data[0]);
                // console.log(response.data.data[0].variants[0].length);
                setMainImage(response.data.data[0].image);

                const faqsFormData = new FormData();
                faqsFormData.append("product_id", ProductId);

                const faqResponse = await axios.post(`${BASE_API_URL}get_product_faqs`, faqsFormData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                setProductFAQs(faqResponse.data.data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [ProductId]);

    useEffect(() => {
        const fetchProductRating = async () => {
            try {
                const formData = new FormData();
                formData.append("product_id", ProductId);

                const response = await axios.post(`${BASE_API_URL}get_product_rating`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setProductRating(response.data);
            } catch (err) {
                // setLoading(false);
                console.log('failed fetching producut rating..!');
            }
        };

        fetchProductRating();
    }, [ProductId]);

    useEffect(() => {
        if (userData.id && ProductId) {
            fetchUserProductRating();
        }
    }, [ProductId, userData]);

    const fetchUserProductRating = async () => {
        try {
            const formData = new FormData();
            formData.append("product_id", ProductId);
            formData.append("user_id", userData.id);

            const response = await axios.post(
                `${BASE_API_URL}get_product_rating`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log(response.data.data[0].rating)

            if (!response.data.error) {
                setUserProductRating(response.data.data[0]);
                setRating(response.data.data[0].rating);
                setReview(response.data.data[0].comment || "");
                setPreviewImages(response.data.data[0].images || []);
            }
        } catch (err) {
            console.log("Failed fetching user product rating");
        }
    };

    useEffect(() => {
        const fetchRecomendedProduct = async () => {
            try {
                const formData = new FormData();
                formData.append("section_id", 1);

                const response = await axios.post(`${BASE_API_URL}get_sections`, formData, {
                    headers: {
                        // Authorization: `${JWT_TOKEN}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                setRecomendedProducts(response.data.data[0].product_details);
                // console.log(response.data.data[0].product_details);
            } catch (err) {
                console.log('failed fetching recomended producut..!');
            }
        };

        fetchRecomendedProduct();
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleIncrement = () => {
        const newQuantity = selectedQuantity + 1;

        if (newQuantity > productDetails?.total_allowed_quantity) {
            setCartErrorMessage(
                `You can only add up to ${productDetails?.total_allowed_quantity} items for this product.`
            );
            return;
        }

        setCartErrorMessage(null);
        setSelectedQuantity(newQuantity);
    };

    const handleDecrement = () => {
        const newQuantity = selectedQuantity - 1;

        if (newQuantity < productDetails?.minimum_order_quantity) {
            setCartErrorMessage(
                `You must add at least ${productDetails?.minimum_order_quantity} items for this product.`
            );
            return;
        }

        setCartErrorMessage(null);
        setSelectedQuantity(newQuantity);
    };

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);

        if (isNaN(value)) {
            setCartErrorMessage("Please enter a valid number.");
            return;
        }

        if (value < productDetails?.minimum_order_quantity) {
            setCartErrorMessage(
                `You must add at least ${productDetails?.minimum_order_quantity} items for this product.`
            );
            return;
        }

        if (value > productDetails?.total_allowed_quantity) {
            setCartErrorMessage(
                `You can only add up to ${productDetails?.total_allowed_quantity} items for this product.`
            );
            return;
        }

        setCartErrorMessage(null);
        setSelectedQuantity(value);
    };

    const handleClickCartBtn = (id) => {
        
        // Check if the selected quantity exceeds the maximum allowed quantity
        if (selectedQuantity > productDetails?.total_allowed_quantity) {
            alert(
                `You can't add this product to the cart. Please reduce the quantity to ${productDetails?.total_allowed_quantity} or less.`
            );
            return;
        }
        
        // Check if the selected quantity is less than the minimum required quantity
        if (selectedQuantity < productDetails?.minimum_order_quantity) {
            alert(
                `You can't add this product to the cart. Please increase the quantity to at least ${productDetails?.minimum_order_quantity}.`
            );
            return;
        }
        setSelectedCartProdId(id);
    };
    
    const handleClickCheckPincodeBtn = (id) => {
        const fetchCheckDeliveryPincode = async () => {
            try {
                const formData = new FormData();
                formData.append("product_id", ProductId);
                formData.append("zipcode", checkPincode);

                console.log(ProductId, checkPincode)

                const response = await axios.post(`${BASE_API_URL}is_product_delivarable`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                setCheckStatus(response.data.message);
                setCheckError(response.data.error);

            } catch (err) {
                console.log(err, 'failed fetching recomended producut..!');
            }
        };

        fetchCheckDeliveryPincode();
    };

    const handleClickFavIcon = (id) => {
        setSelectedFavProdId(id);
    };

    const handleRating = (rate) => {
        setRating(rate);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newImages = [...images, ...files];
        setImages(newImages);
        const newPreviews = newImages.map((file) => URL.createObjectURL(file));
        setPreviewImages(newPreviews);
    };

    const removeImage = (index) => {
        const updatedImages = [...images];
        const updatedPreview = [...previewImages];
        updatedImages.splice(index, 1);
        updatedPreview.splice(index, 1);
        setImages(updatedImages);
        setPreviewImages(updatedPreview);
    };

    const handleSubmit = async () => {
        if (!rating || !review) {
            alert("Please fill all the required fields!");
            return;
        }

        const formData = new FormData();
        formData.append("user_id", userData.id);
        formData.append("product_id", ProductId);
        formData.append("comment", review);
        formData.append("rating", rating);

        images.forEach((image, index) => {
            formData.append(`images[${index}]`, image);
        });

        try {
            const response = await axios.post(`${BASE_API_URL}set_product_rating`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response.data);
            if (response.data.error) {
                alert(response.data.message || "Failed to submit review.");
            } else {
                alert("Review submitted successfully!");
                fetchUserProductRating();
            }
        } catch (error) {
            console.error("Error submitting review:", error);
            alert("An error occurred while submitting your review.");
        }
    };

    const renderContent = (content, showFull, setShowFull) => {
        if (!content) return null;

        const isLongContent = content.length > 200;
        const displayedContent = showFull 
            ? content 
            : `${content.slice(0, 200)}${isLongContent ? '...' : ''}`;

        return (
            <>
                <div dangerouslySetInnerHTML={{ __html: displayedContent }}></div>
                {isLongContent && (
                    <button
                        onClick={() => setShowFull(!showFull)}
                        className="btn btn-link p-0 text-primary"
                        style={{ marginTop: '-15px' }}
                    >
                        {showFull ? 'Show Less' : 'Show More'}
                    </button>
                )}
            </>
        );
    };

    
    return (
        <Base>
            <div className="container-fluid my-2">
                <div className="container product-details font-rock">
                    <p className="font-rock">Home &gt; {productDetails?.category_name} &gt; {productDetails?.name}</p>
                    <div className="row">
                        <div className="col-lg-6 my-3 d-flex justify-content-center">
                            {/* <div className="image-gallery">
                                <div className="main-image">
                                    <img
                                        id="mainImage"
                                        src={mainImage}
                                        className="img-fluid"
                                        alt="Main Image"
                                        style={{ height: '430px', width: '430px' }}
                                    />
                                </div>
                                <div className="d-flex overflow-auto p-2" style={{ gap: "10px" }}>
                                    <img
                                        className="thumbnail"
                                        src={productDetails?.image}
                                        alt="Thumbnail"
                                        onClick={() => setMainImage(productDetails?.image)}
                                        // className="img-thumbnail"
                                        style={{ maxHeight: "100px", cursor: "pointer" }}
                                    />

                                    {productDetails?.other_images?.map((item, index) => (
                                        <img
                                            className="thumbnail img-thumbnail"
                                            key={index}
                                            src={item}
                                            alt={`Thumbnail ${index + 1}`}
                                            onClick={() => handleThumbnailClick(productDetails?.other_images_md[index])}
                                            style={{ maxHeight: "100px", cursor: "pointer" }}
                                        />
                                    ))}
                                </div>
                            </div> */}

                            {/* <div className="image-gallery">
                                <div className="main-image">
                                    <ReactImageMagnify
                                        {...{
                                            smallImage: {
                                                alt: 'Main Image',
                                                isFluidWidth: true,
                                                src: mainImage,
                                                style: { height: '430px', width: '430px' }
                                            },
                                            largeImage: {
                                                src: mainImage,
                                                width: 1200,
                                                height: 1200
                                            },
                                            enlargedImagePosition: "over",  // Magnifier overlay on top
                                            isHintEnabled: true,
                                            shouldUsePositiveSpaceLens: true
                                        }}
                                    />
                                </div>

                                <div className="d-flex overflow-auto p-2" style={{ gap: "10px" }}>
                                    <img
                                        className="thumbnail"
                                        src={productDetails?.image}
                                        alt="Thumbnail"
                                        onClick={() => setMainImage(productDetails?.image)}
                                        style={{ maxHeight: "100px", cursor: "pointer" }}
                                    />

                                    {productDetails?.other_images?.map((item, index) => (
                                        <img
                                            className="thumbnail img-thumbnail"
                                            key={index}
                                            src={item}
                                            alt={`Thumbnail ${index + 1}`}
                                            onClick={() => handleThumbnailClick(productDetails?.other_images_md[index])}
                                            style={{ maxHeight: "100px", cursor: "pointer" }}
                                        />
                                    ))}
                                </div>
                            </div> */}

                            <div className="image-gallery">
                                {/* Main Image with Magnifier */}
                                <div className="main-image">
                                    <ReactImageMagnify
                                        {...{
                                            smallImage: {
                                                alt: 'Main Image',
                                                isFluidWidth: true,
                                                src: mainImage,
                                                style: { height: '430px', width: '430px' }
                                            },
                                            largeImage: {
                                                src: mainImage,
                                                width: 1200,
                                                height: 1200
                                            },
                                            enlargedImagePosition: "over",  // Magnifier overlay on top
                                            isHintEnabled: true,
                                            shouldUsePositiveSpaceLens: true
                                        }}
                                    />
                                </div>

                                {/* Thumbnails Section */}
                                <div className="d-flex overflow-auto p-2" style={{ gap: "10px" }}>
                                    {/* Main Thumbnail */}
                                    <img
                                        className="thumbnail"
                                        src={productDetails?.image}
                                        alt="Thumbnail"
                                        onMouseEnter={() => setMainImage(productDetails?.image)}  
                                        style={{ maxHeight: "100px", cursor: "pointer" }}
                                    />

                                    {/* Other Thumbnails */}
                                    {productDetails?.other_images?.map((item, index) => (
                                        <img
                                            className="thumbnail img-thumbnail"
                                            key={index}
                                            src={item}
                                            alt={`Thumbnail ${index + 1}`}
                                            onMouseEnter={() => setMainImage(item)}
                                            style={{ maxHeight: "100px", cursor: "pointer" }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 my-3">
                            <div className="">
                                <h1 className="fs-4">{productDetails?.name}</h1>
                                <p className="text-warning mb-1 text-primary d-flex">
                                    <div className="me-2">
                                        {[...Array(5)].map((_, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    color: index < parseInt(productRating.product_rating) ? "gold" : "gray",
                                                }}
                                            >
                                                ★
                                            </span>
                                        ))}
                                    </div>
                                    <span className="text-muted">({productRating?.total > 0 ? productRating?.total : 'No Reviews'})</span>
                                </p>
                                {/* <div className="d-flex align-items-center mb-3">
                                    <span className="product-price fs-2">
                                        ₹{Number(productDetails?.min_max_price?.special_price).toFixed(2)}
                                    </span>
                                    <span className="ms-3 original-price text-decoration-line-through fs-4">
                                        ₹{Number(productDetails?.min_max_price?.max_price).toFixed(2)}
                                    </span>
                                </div> */}
                                <div className="text-center product-card-content d-flex align-items-center">
                                    <span className="product-price fs-2 fw-bold text-dark">
                                        <p>₹{Number(productDetails?.min_max_price?.special_price).toFixed(2)}</p>
                                    </span>

                                    <span className="product-discount text-danger ms-2 fs-4">
                                        <p><del>₹{Number(productDetails?.min_max_price?.max_price).toFixed(2)}</del></p>
                                    </span>
                                </div>
                                {productDetails?.min_max_price?.max_price > productDetails?.min_max_price?.special_price && (
                                    <span className="text-success fs-5 fw-bold">
                                        💰 You Save  <span style={{ fontFamily: 'monospace' }}>₹{(productDetails?.min_max_price?.max_price - productDetails?.min_max_price?.special_price).toFixed(2)}</span>
                                    </span>
                                )}

                                <p className="text-muted">Tax included. Shipping calculated at checkout.</p>
                                <div className="d-flex align-items-center mb-4">
                                    <button
                                        className="btn btn-outline-secondary decrement-btn"
                                        onClick={handleDecrement}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="text"
                                        className="form-control text-center mx-2 quantity-input"
                                        style={{ width: "50px" }}
                                        value={selectedQuantity}
                                        onChange={handleChange}
                                    />
                                    <button
                                        className="btn btn-outline-secondary increment-btn"
                                        onClick={handleIncrement}
                                    >
                                        +
                                    </button>
                                </div>
                                {cartErrorMessage && <p className="alert alert-danger">{cartErrorMessage}</p> }
                                <div className="d-flex">
                                    {productDetails?.variants?.[0]?.length > 0 && (
                                        <p className="me-3 product-price text-dark">
                                            <span>Length: </span>
                                            <span className="fw-bold fs-5">{productDetails.variants[0].length} cm</span>
                                        </p>
                                    )}
                                    
                                    {productDetails?.variants?.[0]?.breadth > 0 && (
                                        <p className="me-3 product-price text-dark">
                                            <span>Width: </span>
                                            <span className="fw-bold fs-5">{productDetails.variants[0].breadth} cm</span>
                                        </p>
                                    )}

                                    {productDetails?.variants?.[0]?.height > 0 && (
                                        <p className="product-price text-dark">
                                            <span>Height: </span>
                                            <span className="fw-bold fs-5">{productDetails.variants[0].height} cm</span>
                                        </p>
                                    )}

                                    {productDetails?.variants?.[0]?.weight > 0 && (
                                        <p className="product-price text-dark">
                                            <span>Weight: </span>
                                            <span className="fw-bold fs-5">{productDetails.variants[0].weight} cm</span>
                                        </p>
                                    )}
                                </div>

                                <p className="fw-bold">Check availability at your pincode</p>
                                <style>
                                    {`
                                        input[type="number"]::-webkit-inner-spin-button,
                                        input[type="number"]::-webkit-outer-spin-button {
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }
                                        input[type="number"] {
                                            -moz-appearance: textfield; /* For Firefox */
                                        }
                                    `}
                                </style>
                                <div className="input-group pincode-input mb-4">
                                    <span className="input-group-text bg-primary text-white">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        maxLength={6} 
                                        minLength={6} 
                                        placeholder="Pincode" 
                                        onChange={(e) => setCheckPincode(e.target.value)}
                                        onWheel={(e) => e.target.blur(e)}
                                    />
                                    <button className="btn bg-secondary text-white" type="button" onClick={() => handleClickCheckPincodeBtn()}>Check</button>
                                </div>
                                <p className={`fw-bold ${checkError ? 'text-danger' : 'text-success'}`}>{checkStatus}</p>
                                <div className="mb-4">
                                    <hr/>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button 
                                            className={`btn text-white fw-bold w-100 mb-4 fs-5 bg-secondary fs-sm-1 ${userData.id ? 'd-none' : 'd-block'}`}
                                            data-bs-toggle="modal"    
                                            data-bs-target="#modal-signin" 
                                        >
                                            ADD TO CART</button>
                                        <button 
                                            className={`btn text-white fw-bold w-100 mb-4 fs-5 bg-secondary fs-sm-1 ${userData.id ? 'd-block' : 'd-none'}`}
                                             onClick={() => handleClickCartBtn(productDetails.variants[0].statistics.product_variant_id)}
                                        >
                                            ADD TO CART</button>
                                        <div className="features-icons d-flex justify-content-around">
                                            <div className="text-center">
                                                <i className="fas fa-ban fa text-muted"></i>
                                                <p className="mt-2">No Cancellation</p>
                                            </div>
                                            <div className="text-center">
                                                <i className="fas fa-undo-alt fa text-muted"></i>
                                                <p className="mt-2">No Returnable</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="social-icons mt-4 text-center d-flex justify-content-center">
                                            <a href="https://www.facebook.com/people/Giivvcom/61566349427625/" target="_blank"><i className="fab fa-facebook fs-4 text-secondary mx-2"></i></a>
                                            <a href="https://x.com/giivv_com" target="_blank"><i className="fab fa-x-twitter fs-4 text-secondary mx-2"></i></a>
                                            <a href="https://www.instagram.com/giivv_com/?hl=en" target="_blank"><i className="fab fa-instagram fs-4 text-secondary mx-2"></i></a>
                                            <a href="https://x.com/giivv_com" target="_blank"><i className="fab fa-twitter fs-4 text-secondary mx-2"></i></a>
                                            <a href="https://www.youtube.com/@Giivv.comofficial" target="_blank"><i className="fa-brands fa-youtube fs-4 text-secondary mx-2"></i></a>
                                            <a href="https://www.youtube.com/@Giivv.comofficial" target="_blank"><i className="fas fa-share-alt fs-4 text-secondary mx-2"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-5 home-description font-rock">
                <div className="d-flex flex-wrap nav nav-tabs">
                    <button
                        className={`tab-button ${activeTab === 'description' ? 'active' : ''}`}
                        onClick={() => handleTabClick('description')}
                    >
                        Description
                    </button>
                    {/* <button
                        className={`tab-button ${activeTab === 'delivery' ? 'active' : ''}`}
                        onClick={() => handleTabClick('delivery')}
                    >
                        Delivery
                    </button> */}
                    <button
                        className={`tab-button ${activeTab === 'reviews' ? 'active' : ''}`}
                        onClick={() => handleTabClick('reviews')}
                    >
                        Reviews
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'faqs' ? 'active' : ''}`}
                        onClick={() => handleTabClick('faqs')}
                    >
                        FAQs
                    </button>
                </div>

                <div id="tab-contents" className="tab-content">
                    <div
                        id="description"
                        className={`tab-pane ${activeTab === 'description' ? 'active' : ''}`}
                    >
                        {activeTab === 'description' && (
                            <>
                                 {productDetails?.short_description && (
                                    <div dangerouslySetInnerHTML={{ __html: productDetails.short_description }}></div>
                                )}

                                {productDetails?.description && (
                                    <>
                                        <p className="fw-bold">Specifications:</p>
                                        {renderContent(productDetails.description, showFullDescription, setShowFullDescription)}
                                    </>
                                )}

                                {productDetails?.extra_description && (
                                    <>
                                        <p className="fw-bold mt-3">Extra Description:</p>
                                        {renderContent(productDetails.extra_description, showFullExtraDescription, setShowFullExtraDescription)}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    <div
                        id="delivery"
                        className={`tab-pane ${activeTab === 'delivery' ? 'active' : ''}`}
                    >
                        {activeTab === 'delivery' && <p>Delivery information goes here...</p>}
                    </div>

                    <div
                        id="reviews"
                        className={`tab-pane ${activeTab === 'reviews' ? 'active' : ''}`}
                    >
                        {activeTab === 'reviews' && (
                            <div className="row">
                                {/* Reviews List */}
                                <div className="col-12 col-md-8 mb-3">
                                    <h4 className="text-center text-md-start">
                                        {productRating && productRating.data && productRating.data.length
                                            ? `${productRating.data.length} Reviews For this Product`
                                            : "No Reviews For this Product"}
                                    </h4>
                                    {productRating?.data?.length > 0 ? (
                                        productRating.data.map((item, index) => {
                                            const formattedDate = item.data_added.split(" ")[0].split("-").reverse().join("-");
                                            return (
                                                <div key={index} className="d-flex flex-wrap align-items-start mb-4">
                                                    <img
                                                        src={UserIcon}
                                                        style={{ width: '4rem', marginRight: '1rem' }}
                                                        alt="User Icon"
                                                        className="me-3"
                                                    />
                                                    <div className="w-100 mt-2">
                                                        <div className="d-flex justify-content-between flex-wrap">
                                                            <h6 className="mb-0">{item.user_name}</h6>
                                                            <div className="text-muted">
                                                                <i className="fa-solid fa-calendar-day me-1"></i>
                                                                <span>{formattedDate}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {[...Array(5)].map((_, idx) => (
                                                                <span
                                                                    key={idx}
                                                                    style={{
                                                                        color: idx < parseInt(item.rating) ? "gold" : "gray",
                                                                        marginRight: "0.2rem",
                                                                    }}
                                                                >
                                                                    ★
                                                                </span>
                                                            ))}
                                                        </div>
                                                        <p>{item.comment}</p>
                                                        <div className="d-flex flex-wrap mt-2">
                                                            {item.images &&
                                                                Array.isArray(item.images) &&
                                                                item.images.map((img, imgIdx) => (
                                                                    <img
                                                                        key={imgIdx}
                                                                        src={img}
                                                                        style={{ width: '6rem', margin: '0.5rem' }}
                                                                        className="rounded"
                                                                        alt="Review"
                                                                    />
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p>No reviews available for this product.</p>
                                    )}
                                </div>

                                {/* Add/Edit Review Section */}
                                <div className="col-12 col-md-4">
                                    <div className="card shadow" style={{ height: "100%" }}>
                                        <div className="card-body">
                                            <h6 className="text-center">
                                                {userProductRating ? "Edit Your Review" : "Add Your Review"}
                                            </h6>
                                            <div className="flex-column mb-2">
                                                <p>Your rating</p>
                                                <Rating onClick={handleRating} ratingValue={rating} />
                                            </div>
                                            <label>Your Review</label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Enter your review..."
                                                value={review}
                                                onChange={(e) => setReview(e.target.value)}
                                            ></textarea>
                                            <label className="form-label mt-2">Images</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                            />
                                            <div className="mt-2">
                                                <div className="d-flex flex-wrap gap-2 mt-3">
                                                    {previewImages.map((image, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                position: "relative",
                                                                display: "inline-block",
                                                                margin: "5px",
                                                            }}
                                                        >
                                                            <img
                                                                src={image}
                                                                alt={`Preview ${index + 1}`}
                                                                style={{
                                                                    height: "80px",
                                                                    width: "80px",
                                                                    objectFit: "cover",
                                                                    borderRadius: "5px",
                                                                }}
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={() => removeImage(index)}
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "5px",
                                                                    right: "5px",
                                                                    background: "red",
                                                                    color: "white",
                                                                    border: "none",
                                                                    borderRadius: "50%",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-secondary rounded-pill w-100 mt-4 text-white fw-bold"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        id="faqs"
                        className={`tab-pane ${activeTab === 'faqs' ? 'active' : ''}`}
                    >
                        {activeTab === 'faqs' && (
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {productFAQs && productFAQs?.map((item, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#flush-collapse-${index}`}
                                                aria-controls={`flush-collapse-${index}`}
                                            >
                                                {item.question}
                                            </button>
                                        </h2>
                                        <div
                                            id={`flush-collapse-${index}`}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={`flush-heading-${index}`}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <div className="recommended-products py-5 bg-primary">
                <h2 className="text-center mb-4 fw-bold text-uppercase text-white font-rock">Recommended Products</h2>
                <div className="row w-100 justify-content-center py-3">
                    <div className="col-10 d-flex">
                        <OwlCarousel 
                            className='owl-theme' 
                            loop 
                            margin={10} 
                            nav={false}
                            // items={4}
                            autoplay={true}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                600: {
                                    items: 2,
                                },
                                978: {
                                    items: 3,
                                },
                                1360: {
                                    items: 4,
                                }
                            }}
                        >
                            {recomendedProducts && recomendedProducts?.map((item, index) => (
                                <div 
                                    className="item" 
                                    style={{ marginRight: '10px', marginLeft: '10px' }} 
                                    key={index}
                                >
                                    <div className="product-card" style={{ height: '100%' }} >
                                        <div className="">
                                            <div className={`product-fav ${userData.id ? 'd-none' : 'd-block'}`}  
                                                data-bs-toggle="modal"    
                                                data-bs-target="#modal-signin"
                                            >
                                                <i className="fa-regular fa-heart"></i>
                                            </div>
                                            <div className={`product-fav ${userData.id ? 'd-block' : 'd-none'}`}  onClick={() => handleClickFavIcon(item.id)}>
                                                <i className="fa-regular fa-heart"></i>
                                            </div>
                                            <div className="product-fav mt-5">
                                                <Link to={`/product-details/${item.id}`}>
                                                    <i className="fa-regular fa-eye text-dark"></i>
                                                </Link>
                                            </div>

                                            <Link to={`/product-details/${item.id}`}>
                                                <img src={item.image} alt="Purple Majesty" style={{ height: '300px', width: '100%' }}/>
                                            </Link>
                                            <div className="product-card-content p-md-3 font-rock bg-white">
                                                <h5 className="fw-bold fs-6" title={`${item.name}`}>{item.name.length > 19 ? `${item.name.substring(0, 19)}...` : item.name}</h5>
                                                <div className="d-flex justify-content-center">
                                                    <p>₹{Number(item.min_max_price.special_price).toFixed(2)}</p>
                                                    <p className="text-danger text-decoration-line-through ms-2">
                                                        ₹{Number(item.min_max_price.max_price).toFixed(2)}
                                                    </p>
                                                </div>
                                                <button className={`btn add-to-cart text-white d-flex m-auto ${userData.id ? 'd-none' : 'd-block'}`} style={{ backgroundColor: '#9c786c' }}
                                                    data-bs-toggle="modal"    
                                                    data-bs-target="#modal-signin" 
                                                >Add to Cart</button>
                                                {/* <button className={`btn add-to-cart text-white d-flex m-auto ${userData.id ? 'd-block' : 'd-none'}`} style={{ backgroundColor: '#9c786c' }}
                                                        onClick={() => handleClickCartBtn(item.variants[0].id)}
                                                >Add to Cart</button> */}
                                                <button
                                                    className={`btn add-to-cart text-white d-flex m-auto ${userData.id ? 'd-block' : 'd-none'}`}
                                                    style={{ backgroundColor: '#9c786c' }}
                                                    onClick={() => {
                                                        const minQty = Number(item.minimum_order_quantity) || 1;
                                                        setSelectedQuantity(minQty); 
                                                        handleClickCartBtn(item.variants[0].id);
                                                    }}
                                                >
                                                    Add to Cart
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>

            <AddToCart productVariantId={selectedCartProdId} qty={selectedQuantity} />
            <AddToFavorite productId={selectedFavProdId} />
            <SignIn />

        </Base>
    )
}