import React, { useEffect, useState} from "react";
import PropTypes from "prop-types";
import { BASE_API_URL, JWT_TOKEN, JWT_TOKEN_POST } from "../Config/Config";

export default function AddToFavorite({ productId }) {

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    useEffect(() => {
        const handleAddToFavorites = async (productId) => {
            const url = `${BASE_API_URL}add_to_favorites`;
            if (!productId) return;
            
            console.log(productId, 'id triggered in fav fun')
            try {
                const formData = new FormData();
                formData.append("product_id", productId);
                formData.append("user_id", userData.id);
                console.log(userData.apikey, 'api gettign from session');
        
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                    },
                    body: formData,
                });
                console.log(response, 'response from api favorites');
        
                if (response.ok) {
                    const data = await response.json();
                    if (!data.error) {
                        console.log("Added to favorites:", data);
                        alert("Product added to favorites successfully!");
                        window.location.reload();
                    }
                } else {
                    console.error("Error adding to favorites:", response.statusText);
                    alert("Failed to add product to favorites.");
                }
            } catch (error) {
                console.error("Error adding to favorites:", error);
                alert("An error occurred while adding to favorites.");
            }
        };

        if (productId) {
            handleAddToFavorites(productId);
        }
    }, [productId]);

}

AddToFavorite.propTypes = {
    productId: PropTypes.string.isRequired,
};
