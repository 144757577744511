import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoImg from "../../Assests/Images/logo-remove.png";
import FooterPaymentImg from "../../Assests/Images/footer-payments.png";
import PlaneImg from "../../Assests/Images/plane.png";
import AppleImg from "../../Assests/Images/apple.png";
import LockImg from "../../Assests/Images/lock.png";
import AndroidImg from "../../Assests/Images/android.png";
import MessageImg from "../../Assests/Images/message.png";
import { BASE_API_URL } from "./Config";
import axios from "axios";
import SignIn from "../Auth/Signin";
import { Popover } from "react-tiny-popover";
import Swal from "sweetalert2";
import RazorPay from "./razorpay.png";
import { Bounce, ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Base = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [width, setWidth] = useState("75%");
  const [favItems, setFavItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [favQuantities, setfavQuantities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [cartQuantities, setCartQuantities] = useState([]);
  const [cartTotalAmount, setCartTotalAmount] = useState(0);
  const [displayNavigations, setDisplayNavigations] = useState(false);
  const [favErrorMessage, setFavErrorMessage] = useState("");
  const [cartErrorMessage, setCartErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [tagProducts, setTagProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpenMobile, setIsPopoverOpenMobile] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [mailLoading, setMailLoading] = useState(false);

  const isValidPincode = (pin) => /^\d{6}$/.test(pin);

  // const showSuccessToast = () => toast.success('✅ Success! Data saved successfully.');

  const checkPincode = async () => {
    if (!isValidPincode(pincode)) {
      toast.error("Please enter a valid 6-digit pincode.");
      return;
    }

    try {
      const response = await axios.get(
        `https://givvseller.ibizaccounts.in/GetIsZipcodeAvailable?zipcode=${pincode}`
      );

      const { Status, ResultData, message } = response.data;

      if (Status && ResultData.length > 0) {
        const deliveryInfo = ResultData[0];
        console.log(deliveryInfo);
        // toast.success(`${message}`);
        toast.success(`${message}\n`, {
          style: {
            backgroundColor: "#867772",
            color: "#fff",
          },
        });
      } else {
        toast.error(`Pincode not available for delivery.`, {
          style: {
            backgroundColor: "#867772",
            color: "#fff",
          },
        });
      }
    } catch (error) {
      toast.error("Failed to fetch data. Please try again later.");
      console.error("Error fetching pincode data:", error);
    }
  };

  useEffect(() => {
    if (isValidPincode(pincode)) {
      checkPincode(pincode);
    }
  }, [pincode]);

  const sendEmail = async () => {
    if (!email) {
      alert("Please enter an email address");
      return;
    }
    setMailLoading(true);

    const payload = {
      superid: 51115,
        toaddr: email,
    //   toaddr: "chaitanya@perennialcode.in",
      message: `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Vendor Support Request</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }
        .container {
            max-width: 600px;
            margin: 20px auto;
            background: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            text-align: left;
        }
        .header {
            background-color: #007bff;
            color: white;
            padding: 15px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            border-radius: 8px 8px 0 0;
        }
        .content {
            padding: 20px;
            font-size: 16px;
            color: #333;
            line-height: 1.6;
        }
        .footer {
            margin-top: 20px;
            font-size: 14px;
            color: #888;
            text-align: center;
        }
        a {
            color: #007bff;
            text-decoration: none;
            font-weight: bold;
        }
        a:hover {
            text-decoration: underline;
        }
    </style>
</head>
<body>

    <div class="container">
        <div class="header">
            💼 Vendor Partnership Invitation
        </div>
        <div class="content">
            <p>Dear Vendor,</p>

            <p>We're excited to invite you to join our <strong>gifting portal</strong> as a channel partner! Our platform is designed to help sellers like you <strong>increase their sales exponentially</strong>, while providing customers with a unique and personalized gifting experience.</p>

            <h3>🎯 Benefits of Partnering with Us:</h3>
            <ul>
                <li><strong>🚀 10x Sales Growth:</strong> Expand your customer base globally and boost sales.</li>
                <li><strong>🌍 Global Exposure:</strong> Showcase your products to a vast audience and increase brand visibility.</li>
                <li><strong>💰 Competitive Commission:</strong> Earn competitive commissions on every sale.</li>
                <li><strong>🤝 Dedicated Support:</strong> Get assistance from our team for a seamless experience.</li>
            </ul>

            <h3>📌 How to Get Started:</h3>
            <ol>
                <li>Register Now: <a href="https://vendor.giivv.com/">Click here</a> to sign up as a seller.</li>
                <li>Upload Your Products: Add your products, and our team will review them.</li>
                <li>Start Selling: Once approved, start selling to a global customer base.</li>
            </ol>

            <p><strong>🌟 Registration Link:</strong> <a href="https://vendor.giivv.com/">https://vendor.giivv.com/</a></p>

            <h3>📞 Contact Information:</h3>
            <p>
                📧 Email: <a href="mailto:giivvinfo@gmail.com">giivvinfo@gmail.com</a><br>
                📞 Phone: <a href="tel:+916309542222">+91 6309542222</a><br>
                🌐 Website: <a href="https://giivv.com/">https://giivv.com/</a>
            </p>

            <p>We look forward to welcoming you on board!</p>

            <p><strong>Best regards,</strong><br>
            <strong>Giivv.com</strong> <br>
            <em>Gifting Portal Team</em></p>
        </div>
    </div>

</body>
</html>

                `,
      subject: `Boost Your Sales 10x Globally! Join Our <a href="https://giivv.com/">Giivv.com</a>  - India's No.1 AI Online Gifting Store as a Channel Partner`,
    };

    try {
      const response = await fetch(
        "https://notifyservisesgivv.azurewebsites.net/notify/sendmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      setMailLoading(false);

      if (response.ok) {
        if (data.status) {
          setMailLoading(false);
          alert("Email sent successfully!");
          setEmail("");
        }
      } else {
        setMailLoading(false);
        alert("Failed to send email: " + (data.message || "Unknown error"));
      }
    } catch (error) {
      setMailLoading(false);
      alert("Error sending email: " + error.message);
    }
  };

  // const handleSearchClick = () => {
  //     setIsSearchOpen((prev) => !prev);
  // };

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("UserData");
    if (storedUserData) {
      try {
        setUserData(JSON.parse(storedUserData));
      } catch (error) {
        console.error("Error parsing storedUserData:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (userData.id > 0) {
      setDisplayNavigations(true);
    }
  }, [userData.id]);

  const dropdownRef = useRef(null);

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value.trim()) {
      try {
        const formDataSearch = new FormData();
        formDataSearch.append("search", value);

        const responseSearch = await axios.post(
          `${BASE_API_URL}get_products`,
          formDataSearch,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const formDataTags = new FormData();
        formDataTags.append("tags", value);

        const responseTags = await axios.post(
          `${BASE_API_URL}get_products`,
          formDataTags,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // console.log(responseTags.data.data, 'responseTagsresponseTags')
        // console.log(responseSearch.data.data, 'responseSearchresponseSearch')

        setProductDetails(responseSearch.data.data || []);
        setTagProducts(responseTags.data.data || []);
        setShowDropdown(true);
      } catch (err) {
        console.error("Error fetching product data:", err);
        setShowDropdown(false);
      }
    } else {
      setProductDetails([]);
      setShowDropdown(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const handleSelectProduct = (product) => {
    // console.log("Selected Product:", product);
    setSearch(product.name); // Update input value
    setShowDropdown(false); // Close dropdown
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setWidth("100%");
      } else {
        setWidth("45%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}get_categories`,
        formData,
        {}
      );
      setCategories(response.data.data.slice(0, 6));
      setAllCategories(response.data.data);
    } catch (err) {
      console.log("failed fetching categories..!");
    }
  };

  const fetchFavItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}get_favorites`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFavItems(response.data.data);
      // console.log(response.data.data);
    } catch (err) {
      console.log("failed fetching fav items..!");
    }
  };

  useEffect(() => {
    fetchCartItems();
    fetchTransactions();
    fetchFavItems();
  }, [userData]);

  const fetchCartItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}get_user_cart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response.data.data);
      setCartItems(response.data.data);
    } catch (err) {
      console.log("failed fetching fav items..!");
    }
  };

  const fetchTransactions = async () => {
    if (userData.id) {
      try {
        const response = await axios.get(
          `https://givvseller.ibizaccounts.in/GetTransactions?UserId=${userData.id}`
        );
        setTransactions(response.data.ResultData);
        // console.log(response.data, "transactions data");
      } catch (error) {
        console.error("Error fetching alert data:", error);
      }
    }
  };

  useEffect(() => {
    if (favItems && favItems.length > 0) {
      setfavQuantities(favItems.map(() => 1));
    }
  }, [favItems]);

  useEffect(() => {
    // console.log(cartItems,'cartItemscartItemscartItemscartItems');
    if (cartItems && cartItems.length > 0) {
      setCartQuantities(
        cartItems.map((item) =>
          Math.max(
            Number(item.minimum_order_quantity) || 1,
            Number(item.qty) || 1
          )
        )
      );
    }
  }, [cartItems]);

  // Centralized Total Calculation Function
  const calculateTotal = (quantities) => {
    const total = (cartItems || []).reduce((sum, item, index) => {
      const price = Number(item.special_price || 0);
      const quantity = quantities[index] || 1;
      return sum + price * quantity;
    }, 0);
    setCartTotalAmount(total);
  };

  const handleCartIncrement = (index) => {
    setCartQuantities((prev) => {
      const updated = [...prev];

      const item = cartItems[index];
      const stepSize = Number(item.quantity_step_size) || 1;
      const maxQty = Number(item.total_allowed_quantity) || Infinity;

      // Increment by step size
      const newQuantity = updated[index] + stepSize;

      if (newQuantity > maxQty) {
        setCartErrorMessage(
          `You can only add up to ${maxQty} items for this product.`
        );
        updated[index] = maxQty; // Force cap at max
      } else {
        updated[index] = newQuantity;
        setCartErrorMessage(null); // Clear error if valid
      }

      calculateTotal(updated);
      return updated;
    });
  };

  const handleCartDecrement = (index) => {
    setCartQuantities((prev) => {
      const updated = [...prev];

      const item = cartItems[index];
      const stepSize = Number(item.quantity_step_size) || 1;
      const minQty = Number(item.minimum_order_quantity) || 1;

      // Decrement by step size
      const newQuantity = updated[index] - stepSize;

      if (newQuantity < minQty) {
        updated[index] = minQty; // Cap at minimum
      } else {
        updated[index] = newQuantity;
      }

      setCartErrorMessage(null); // Clear error (optional, handle only if needed)

      calculateTotal(updated);
      return updated;
    });
  };

  const handleFavIncrement = (index) => {
    setfavQuantities((prev) => {
      const updated = [...prev];
      const maxAllowed = favItems[index].total_allowed_quantity || Infinity;

      if (updated[index] + 1 > maxAllowed) {
        setFavErrorMessage((errors) => ({
          ...errors,
          [index]: `Maximum quantity allowed for ${favItems[index].name} is ${maxAllowed}.`,
        }));
        return prev;
      }

      setFavErrorMessage((errors) => {
        const updatedErrors = { ...errors };
        delete updatedErrors[index];
        return updatedErrors;
      });

      updated[index] += 1;
      return updated;
    });
  };

  const handleFavDecrement = (index) => {
    setfavQuantities((prev) => {
      const updated = [...prev];
      updated[index] = Math.max(1, updated[index] - 1);

      setFavErrorMessage((errors) => {
        const updatedErrors = { ...errors };
        delete updatedErrors[index]; // Clear error for this product
        return updatedErrors;
      });

      return updated;
    });
  };

  const totalAmount = (favItems || []).reduce((total, item, index) => {
    const price = Number(item.min_max_price?.special_price || 0);
    const quantity = favQuantities[index] || 1;
    return total + price * quantity;
  }, 0);

  const ClearFavItem = async (id) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);
      formData.append("product_id", id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_favorites`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);

      fetchFavItems();
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const ClearAllFavItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_favorites`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchFavItems();
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const ClearCartItem = async (id) => {
    console.log("celar step cart item fectching", id);
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);
      formData.append("product_variant_id", id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_cart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      if (response.data.error) {
        setCartErrorMessage(response.data.message);
      } else {
        fetchCartItems();
        fetchFavItems();
        console.log("fetching cart items afetr remove");
      }
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const ClearAllCartItems = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);

      const response = await axios.post(
        `${BASE_API_URL}remove_from_cart`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userData.apikey}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchCartItems();
      fetchFavItems();
    } catch (err) {
      console.error("Failed fetching favorite items:", err);
    }
  };

  const togglePopover = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const togglePopoverMobile = () => {
    setIsPopoverOpenMobile((prev) => !prev);
  };

  const handleLogout = (e) => {
    console.log("click");
    e.stopPropagation();
    // e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to log out?",
      text: "You will need to log in again to access your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.clear();
        setDisplayNavigations(false);
        Swal.fire(
          "Logged Out!",
          "You have been successfully logged out.",
          "success"
        );
      }
    });
  };

  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsPopoverOpen(false);
        setIsPopoverOpenMobile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddToCart = async (qty, productVariantId) => {
    const url = `${BASE_API_URL}manage_cart`;

    if (!productVariantId || qty < 1)
      return alert("Invalid product or quantity");

    try {
      const formData = new FormData();
      formData.append("user_id", userData.id);
      formData.append("qty", qty);
      formData.append("product_variant_id", productVariantId);

      console.log("Sending request with API key:", userData.apikey);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userData.apikey}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Cart response:", data);

        if (!data.error) {
          alert("Product added to cart successfully!");
          console.log("celar step1");
          ClearFavItem(productVariantId);
          window.location.reload();
        } else {
          alert("Failed to add product to cart. Error: " + data.message);
        }
      } else {
        console.error("Error adding to cart:", response.statusText);
        alert("Failed to add product to cart.");
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      alert("An error occurred while adding to cart.");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleCheckout = async () => {
    console.log("click checkout");
    const userId = userData.id;

    const cartData = cartItems.map((item, index) => ({
      product_variant_id: item.product_variant_id,
      qty: cartQuantities[index] || 1,
      user_id: userId,
    }));

    try {
      const promises = cartData.map(async (data) => {
        const formData = new FormData();
        formData.append("product_variant_id", data.product_variant_id);
        formData.append("qty", data.qty);
        formData.append("user_id", data.user_id);

        formData.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });

        const response = await axios.post(
          `${BASE_API_URL}manage_cart`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userData.apikey}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response.data);
        if (response.data.error) {
          alert(response.data.message);
        }
        return response.data;
      });

      const results = await Promise.all(promises);
      if (!results[0].error) {
        alert("Cart updated successfully! Redirecting to checkout...");
        // navigate("/billing-details");
        window.location.href = "/view-cart";
      }

      // console.log("Cart updated successfully:", results);
    } catch (error) {
      console.error("Error updating cart:", error);
      alert("Failed to update the cart. Please try again.");
    }
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}get_settings`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.data.error) {
        sessionStorage.setItem(
          "settingsData",
          JSON.stringify(response.data.data)
        );
      }
      // console.log('Settings fetched successfully:', response.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    if (!search) {
      alert("Please enter tag name..!");
      return;
    }
    const offcanvasElement = document.getElementById(
      "offcanvasRightPassCheckIn"
    );
    if (offcanvasElement) {
      offcanvasElement.classList.remove("show");
      document.body.classList.remove("offcanvas-backdrop");
    }

    navigate(path);
    window.location.reload();
  };

  const navListStyle = {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    padding: "10px 0",
  };

  const navItemStyle = {
    padding: "12px 16px",
    borderBottom: "1px solid #e0e0e0",
    textDecoration: "none",
    color: "#333",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    transition: "background 0.3s ease, color 0.3s ease",
  };

  const navIconStyle = {
    fontSize: "18px",
  };

  return (
    <div style={{ fontFamily: "raleway" }}>
      <header className="container-fluid">
        <div className="header-top container py-4 d-lg-block d-none">
          <div className="row align-items-center">
            <div className="col-md-2">
              <Link to="/" className="logo">
                <img src={LogoImg} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="col-md-7 d-none d-lg-block">
              <div className="row">
                <div className="col-lg-9 col-md-7" ref={dropdownRef}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleNavigation(`/products/0/${search}/1`);
                    }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control rounded-0"
                        placeholder="Search for gifts"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      <button
                        type="submit"
                        className="bg-secondary text-white border-0 p-2 px-3"
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </form>

                  {showDropdown &&
                    (productDetails.length > 0 || tagProducts.length > 0) && (
                      <>
                        <style>
                          {`
                                                @media (max-width: 991px) {
                                            .custom-dropdown-width {
                                                width: 360px;
                                                }
                                            }

                                            @media (min-width: 992px) and (max-width: 1366px) {
                                                .custom-dropdown-width {
                                                    width: 430px;
                                                }
                                            }

                                            @media (min-width: 1367px) {
                                                .custom-dropdown-width {
                                                    width: 506px;
                                                }
                                            }
                                            `}
                        </style>
                        <ul
                          className="dropdown-menu show  position-absolute custom-dropdown-width"
                          style={{
                            zIndex: 1000,
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                        >
                          {/* Render Tags */}
                          {tagProducts.length > 0 && (
                            <>
                              <li className="dropdown-header">Tags</li>
                              {tagProducts.map((product, index) => (
                                <li
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() => handleSelectProduct(product)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link
                                    to={`/product-details/${product.id}`}
                                    className="text-dark text-decoration-none"
                                  >
                                    <img
                                      src={product.image}
                                      style={{ width: "2rem" }}
                                      className="me-2 rounded"
                                    />
                                    {product.name}
                                  </Link>
                                </li>
                              ))}
                              <li className="dropdown-divider"></li>
                            </>
                          )}

                          {/* Render Products */}
                          {productDetails.length > 0 && (
                            <>
                              <li className="dropdown-header">Products</li>
                              {productDetails.map((product, index) => (
                                <li
                                  key={index}
                                  className="dropdown-item"
                                  onClick={() => handleSelectProduct(product)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Link
                                    to={`/product-details/${product.id}`}
                                    className="text-dark text-decoration-none"
                                  >
                                    <img
                                      src={product.image}
                                      style={{ width: "2rem" }}
                                      className="me-2 rounded"
                                    />
                                    {product.name}
                                  </Link>
                                </li>
                              ))}
                            </>
                          )}
                        </ul>
                      </>
                    )}
                </div>

                <div className="col-lg-3 col-md-5">
                  <div className="input-group">
                    <input
                        type="text"
                        className="form-control rounded-0 monospace-text"
                        placeholder="Pincode"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        maxLength={6}
                        />

                        <style>
                            {`
                            
                                .monospace-text {
                                    font-family: monospace;
                                }
                                .monospace-text::placeholder {
                                    font-family:raleway, sans-serif /* or any other font */
                                }
                            `}
                        </style>
                    <button
                      className="bg-secondary text-white border-0 p-2 px-3"
                      // onClick={() => window.open("https://www.google.com/maps", "_blank")}
                    >
                      <i className="fa-solid fa-location-dot"></i>
                    </button>
                  </div>
                </div>
                {/* <button className="btn btn-success" onClick={showSuccessToast}>Show Success Toast</button> */}

                <ToastContainer
                  position="top-right"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={true}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                  transition={Zoom}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-end align-items-center">
              <div className="d-none d-md-flex gap-4">
                <a
                  className="text-secondary"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightTrans"
                  aria-controls="offcanvasRightTrans"
                >
                  <i className="fa-solid fa-indian-rupee-sign fs-4"></i>
                </a>
                <a
                  className={`text-secondary user-icon ${
                    displayNavigations ? "d-none" : "d-block"
                  }`}
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#modal-signin"
                >
                  <i className="fa-regular fa-user fs-4"></i>
                </a>
                {displayNavigations && (
                  <div style={{ zIndex: "1000" }}>
                    <Popover
                      isOpen={isPopoverOpen}
                      positions={["top", "bottom", "left", "right"]}
                      content={
                        <div
                          className="mt-4 card shadow"
                          ref={popoverRef}
                          style={{ width: "10rem" }}
                        >
                          <div className="card-bod">
                            <h6 className="text-center mt-2 text-secondary">
                              <i className="fa-regular fa-circle-user me-2 fa-lg"></i>
                              {userData?.username}
                            </h6>
                            <hr />
                            <ul
                              style={{ listStyleType: "none" }}
                              className="mt-2"
                            >
                              <li>
                                <Link
                                  to="/my-account?content=myOrdersContent"
                                  className="text-dark"
                                  style={{ textDecoration: "none" }}
                                >
                                  <i className="fa-solid fa-boxes-stacked me-3"></i>
                                  Orders
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/my-account"
                                  className="text-dark"
                                  style={{ textDecoration: "none" }}
                                >
                                  <i className="fa-regular fa-user me-3 my-3"></i>
                                  Profile
                                </Link>
                              </li>
                              <li
                                onClick={handleLogout}
                                className="text-dark"
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa-solid fa-arrow-right-from-bracket me-3"></i>
                                Logout
                              </li>
                            </ul>
                          </div>
                        </div>
                      }
                    >
                      <div onClick={togglePopover}>
                        <i
                          className="fa-regular fa-user text-secondary fs-4"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </Popover>
                  </div>
                )}
                <div
                  className="position-relative"
                  style={{ display: "inline-block" }}
                >
                  <a
                    className="text-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-regular fa-heart fs-4"></i>
                  </a>
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                    style={{
                      fontSize: "0.75rem",
                      padding: "2px 6px",
                      fontFamily: "monospace"
                    }}
                  >
                    {(favItems && favItems.length) || 0}
                  </span>
                </div>
                <div
                  className="position-relative"
                  style={{ display: "inline-block" }}
                >
                  <a
                    className="text-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightCart"
                    aria-controls="offcanvasRightCart"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-solid fa-cart-shopping fs-4"></i>
                  </a>
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                    style={{
                        fontSize: "0.75rem",
                        padding: "2px 6px",
                        fontFamily: "monospace"
                    }}
                  >
                    {(cartItems && cartItems.length) || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile navigation */}
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container px-0 d-flex align-items-end">
            <a className="navbar-brand d-lg-none d-block p-0" href="/">
              <img src={LogoImg} className="img-fluid" alt="Logo" />
            </a>
            <div className="d-lg-none d-block d-flex ms-auto">
              <a
                className={`text-secondary user-icon mx-2 ${
                  displayNavigations ? "d-none" : "d-block"
                }`}
                style={{ cursor: "pointer" }}
                data-bs-toggle="modal"
                data-bs-target="#modal-signin"
              >
                <i className="fa-regular fa-user fs-4"></i>
              </a>

              {displayNavigations && (
                <Popover
                  containerStyle={{ zIndex: 20000 }}
                  isOpen={isPopoverOpenMobile}
                  positions={["top", "bottom", "left", "right"]}
                  content={
                    <div
                      className="mt-4 card shadow"
                      ref={popoverRef}
                      style={{ width: "10rem" }}
                    >
                      <div className="card-bod">
                        <h6 className="text-center mt-2 text-secondary">
                          <i className="fa-regular fa-circle-user me-2 fa-lg"></i>
                          {userData?.username}
                        </h6>
                        <hr />
                        <ul style={{ listStyleType: "none" }} className="mt-2">
                          <li>
                            <Link
                              to="/my-account?content=myOrdersContent"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                              onClick={() => setIsPopoverOpenMobile(false)}
                            >
                              <i className="fa-solid fa-boxes-stacked me-3"></i>
                              Orders
                            </Link>
                          </li>
                          <li className="mt-3">
                            <a
                              className="text-dark text-decoration-none"
                              style={{ cursor: "pointer" }}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRightTrans"
                              aria-controls="offcanvasRightTrans"
                              onClick={() => setIsPopoverOpenMobile(false)}
                            >
                              <i className="fa-solid fa-indian-rupee-sign fs-4 me-2"></i>
                              Transactions
                            </a>
                          </li>
                          <li>
                            <Link
                              to="/my-account"
                              className="text-dark"
                              style={{ textDecoration: "none" }}
                            >
                              <i className="fa-regular fa-user me-3 my-3"></i>
                              Profile
                            </Link>
                          </li>
                          <li
                            onClick={(e) => {
                              handleLogout(e);
                              setIsPopoverOpenMobile(false);
                            }}
                            className="text-dark"
                            style={{ textDecoration: "none" }}
                          >
                            <i className="fa-solid fa-arrow-right-from-bracket me-3"></i>
                            Logout
                          </li>
                        </ul>
                      </div>
                    </div>
                  }
                >
                  <div onClick={togglePopoverMobile}>
                    <i
                      className="fa-regular fa-user text-secondary fs-4"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </Popover>
              )}

              <div
                className="position-relative me-2"
                style={{ display: "inline-block" }}
              >
                <a
                  className="text-secondary mx-1"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <i className="fa-regular fa-heart fs-4"></i>
                </a>
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                  style={{
                    fontSize: "0.65rem",
                    padding: "2px 5px",
                    marginRight: "10px",
                    fontFamily: "monospace"
                  }}
                >
                  {(favItems && favItems?.length) || 0}
                </span>
              </div>

              <div
                className="position-relative me-3"
                style={{ display: "inline-block" }}
              >
                <a
                  className="text-secondary mx-1"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightCart"
                  aria-controls="offcanvasRightCart"
                >
                  <i className="fa-solid fa-cart-shopping fs-4"></i>
                </a>
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                  style={{
                    fontSize: "0.65rem",
                    padding: "2px 5px",
                    marginRight: "10px",
                    fontFamily: "monospace"
                  }}
                >
                  {(cartItems && cartItems?.length) || 0}
                </span>
              </div>

              <div className="position-relative d-flex align-items-center">
                <i
                  className="fa-solid fa-bars fa-lg text-secondary d-block d-md-none"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeftSidebar"
                  aria-controls="offcanvasLeftSidebar"
                ></i>
              </div>
            </div>

            <div className="d-none d-md-flex align-items-center justify-content-around mx-0 w-100">
              <a
                href={`/products/0/ALL/0`}
                className="fw-bold font-rock text-decoration-none text-black"
              >
                ALL
              </a>
              {categories &&
                categories.map((item, index) => (
                  <a
                    href={`/products/${item.id}/${item.name}/0`}
                    key={index}
                    className="nav-item text-uppercase font-rock fw-bold text-decoration-none text-black"
                  >
                    {item.name}
                  </a>
                ))}
            </div>
          </div>
        </nav>
      </header>

      <div className="hr container border-bottom-2 mb-4"></div>
      <form
        className="d-block d-md-none"
        onSubmit={(e) => {
          e.preventDefault();
          handleNavigation(`/products/0/${search}/1`);
        }}
      >
        <div className="input-group px-2">
          <input
            type="text"
            className={`form-control mb-3  container`}
            placeholder="Search for gifts"
            value={search}
            onChange={handleSearchChange}
            style={{ height: "40px" }}
          />
          <button
            className={`bg-secondary text-white border-0 p-2 px-3 btn-sm`}
            type="submit"
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              padding: "0 12px",
            }}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
      </form>
      <main className=" font-rock">
        <div className="container d-block d-md-none">
          {isSearchOpen &&
            (productDetails.length > 0 || tagProducts.length > 0) && (
              <ul
                className="dropdown-menu show position-absolute"
                style={{
                  maxWidth: "93%",
                  width: "360px",
                  maxHeight: "400px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                  marginLeft: "0px",
                }}
              >
                {tagProducts.length > 0 && (
                  <>
                    <li className="dropdown-header">Tags</li>
                    {tagProducts.map((product, index) => (
                      <li
                        key={index}
                        className="dropdown-item"
                        onClick={() => handleSelectProduct(product)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        <Link
                          to={`/product-details/${product.id}`}
                          className="text-dark text-decoration-none"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            src={product.image}
                            alt=""
                            style={{
                              width: "2rem",
                              height: "2rem",
                              objectFit: "cover",
                              marginRight: "10px",
                            }}
                          />
                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {product.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                    <li className="dropdown-divider"></li>
                  </>
                )}

                {productDetails.length > 0 && (
                  <>
                    <li className="dropdown-header">Products</li>
                    {productDetails.map((product, index) => (
                      <li
                        key={index}
                        className="dropdown-item"
                        onClick={() => handleSelectProduct(product)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        <Link
                          to={`/product-details/${product.id}`}
                          className="text-dark text-decoration-none"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            src={product.image}
                            alt=""
                            style={{
                              width: "2rem",
                              height: "2rem",
                              objectFit: "cover",
                              marginRight: "10px",
                            }}
                          />
                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {product.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            )}
        </div>

        {children}

        <div
          className="home-join py-5 bg-primary"
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://vendor.giivv.com/", "_blank")}
        >
          <div className="join-inner container font-rock">
            <h2 className="text-center text-white fs-1 mb-3">
              JOIN THE GIIVV CLUB
            </h2>
            <h4 className="text-center">
              Register with us and Multiply your Sales to 10x Globally
            </h4>
            <div
              className="row justify-content-center w-100 my-4"
              style={{ zIndex: "10000" }}
            >
              <div className="col-lg-4">
                <div className="join-input position-relative">
                  <input
                    type="text"
                    placeholder="Email address"
                    className="w-100 border-0 fs-5 p-2 pe-5" // Added `pe-5` for right padding
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    disabled={mailLoading}
                  />
                  <div
                    className="join-icon position-absolute"
                    onClick={(e) => {
                      e.stopPropagation();
                      sendEmail();
                    }}
                    style={{
                      cursor: mailLoading ? "not-allowed" : "pointer",
                      opacity: mailLoading ? 0.5 : 1,
                      right: "15px", // Positioning fix
                      top: "50%",
                      transform: "translateY(-50%)", // Center align vertically
                    }}
                  >
                    {mailLoading ? (
                      <i className="fas fa-spinner fa-spin fs-2 text-secondary"></i>
                    ) : (
                      <i className="fas fa-envelope fs-2 text-secondary"></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-services py-5 bg-light">
          <div className="container">
            <div className="home-service-inner row font-rock">
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  {" "}
                  <img src={PlaneImg} className="w-20 my-4" alt="" />
                </div>
                <h5 className="text-center fw-bold">Worldwide Delivery</h5>
              </div>
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  {" "}
                  <img src={LockImg} className="w-20 my-3" alt="" />
                </div>
                <h5 className="text-center fw-bold">
                  100% Safe & Secure Payments
                </h5>
              </div>
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  {" "}
                  <img src={MessageImg} className="w-20 my-4" alt="" />
                </div>
                <h5 className="text-center fw-bold">Dedicated Help Center</h5>
              </div>
              <div className="home-service-one col-lg-3 col-md-4 col-6">
                <div className="text-center">
                  <img src={AndroidImg} className="w-20 my-4" alt="" />
                  <img src={AppleImg} className="w-20 my-4" alt="" />
                </div>
                <h5 className="text-center fw-bold">Shop on the go</h5>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div className="footer container-fluid py-3">
          <div className="container footer-inner py-3">
            <div className="row font-rock">
              <div className="col-lg-2 col-md-4 col-6 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-col">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">SERVICES</h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link
                        to="/my-account?content=myDetailsContent"
                        className="text-dark text-decoration-none"
                      >
                        My Account
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/my-account?content=myDetailsContent"
                        className="text-dark text-decoration-none"
                      >
                        Track Order
                      </Link>
                    </li>
                    <li className="mb-2">
                      <a href="#" className="text-dark text-decoration-none">
                        Delivery
                      </a>
                    </li>
                    <li
                      className="mb-2"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      style={{ cursor: "pointer" }}
                    >
                      <a className="text-dark text-decoration-none">Wishlist</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-col">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">
                    Information
                  </h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link
                        to="/privacy-policy"
                        className="text-dark text-decoration-none"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/return-policy"
                        className="text-dark text-decoration-none"
                      >
                        Returns Policy
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/terms-and-conditions"
                        className="text-dark text-decoration-none"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-col">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">About Us</h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <a className="text-dark text-decoration-none">
                        Meet The Team
                      </a>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/contact-us"
                        className="text-dark text-decoration-none"
                      >
                        Contact Us
                      </Link>
                    </li>
                    {/* <li className="mb-2">
                                            <a
                                                href="https://vendor.giivv.com/"
                                                target="_blank"
                                                className="text-dark text-decoration-none"
                                            >
                                                Vendor Registration
                                            </a>
                                        </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <div className="foot-col">
                  <div className="row">
                    <div className="col-md-8 d-flex justify-content-md-start justify-content-center  my-3">
                      <div className="text-md-start text-center">
                        <h5 className="fw-bold mb-3 text-uppercase fs-6">
                          Secure Payments
                        </h5>
                        <img
                          src={RazorPay}
                          className="img-fluid"
                          style={{ width: "8rem" }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-md-start text-md-start text-center justify-content-center my-3">
                <div className="foot-social">
                  <h5 className="fw-bold mb-3 text-uppercase fs-6">
                    Social Media
                  </h5>
                  <div className="foot-icons d-flex justify-content-between">
                    <a
                      href="https://www.facebook.com/people/Giivvcom/61566349427625/"
                      target="_blank"
                      className="text-secondary fs-4 mx-2"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://x.com/giivv_com"
                      className="text-secondary fs-4 mx-2"
                      target="_blank"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/giivv_com/?hl=en"
                      target="_blank"
                      className="text-secondary fs-4 mx-2"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a
                      // target="_blank"
                      className="text-secondary fs-4 mx-2"
                    >
                      <i className="fa-brands fa-pinterest"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/@Giivv.comofficial"
                      target="_blank"
                      className="text-secondary fs-4 mx-2"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom bg-primary py-2 font-rock">
          <p className="text-white text-center mb-0 fs-5">
            Copyright 2024 giivv | All rights reserved |
          </p>
        </div>
      </footer>

      {/* Favorite Items */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ width }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">MY WISHLIST</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div
            id="myWishlistContent"
            className="content-section d-none d-lg-block"
          >
            <div className="wish-head pb-3 border-bottom-2">
              <div className="row">
                <div className="col-5">
                  <p className="mb-0">Product</p>
                </div>
                <div className=" col-2">
                  <p className="mb-0">Price</p>
                </div>
                <div className=" col-3">
                  <p className="mb-0">Quantity</p>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <p className="mb-0">Total</p>
                </div>
              </div>
            </div>
            {favItems &&
              favItems.map((item, index) => {
                const price = Number(item.variants[0]?.price || 0);
                const quantity = favQuantities[index] || 1;

                return (
                  <div
                    className="wish-content py-3 border-bottom-2"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-5">
                        <div className="wish-details row align-items-center my-2">
                          <div className="col-12 col-lg-4 my-2">
                            {item.image ? (
                              <img
                                src={item.image}
                                className="img-fluid w-100 rounded"
                                alt={item.name || "Product Image"}
                              />
                            ) : (
                              <div
                                className="img-fluid w-100 d-flex align-items-center justify-content-center"
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  height: "100px",
                                  fontSize: "24px",
                                  color: "#333",
                                }}
                              >
                                {item.name?.charAt(0).toUpperCase() || "N"}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-lg-8 ps-lg-4 my-2">
                            <h6 className="fw-bold" title={item.name}>
                              {item.name.length > 16
                                ? item.name.substring(0, 16) + "..."
                                : item.name}
                            </h6>
                            <div className="wish-ratings text-secondary">
                              <span>
                                {[...Array(5)].map((_, idx) => (
                                  <span
                                    key={idx}
                                    style={{
                                      color:
                                        idx < Math.round(Number(item.rating))
                                          ? "gold"
                                          : "lightgray",
                                    }}
                                  >
                                    ★
                                  </span>
                                ))}
                              </span>
                              <span className="text-muted">
                                ({item.no_of_ratings})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center product-card-content">
                        <p className="mb-0">₹{price}</p>
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div>
                          <div
                            className="d-flex align-items-center px-md-1 border-secondary"
                            style={{ height: "3rem" }}
                          >
                            <button
                              className="btn btn-outline-secondary decrement-btn border-0 p-1 fs-4"
                              onClick={() => handleFavDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="form-control text-center mx-1 quantity-input border-0"
                              style={{ width: "40px", height: "1.5rem" }}
                              value={quantity}
                              readOnly
                            />
                            <button
                              className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                              onClick={() => handleFavIncrement(index)}
                            >
                              +
                            </button>
                          </div>
                          {favErrorMessage && (
                            <p
                              className="text-danger mt-2"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {favErrorMessage[index]}
                            </p>
                          )}
                          <p className="fs-8 mb-0 mt-3">
                            Remove Item
                            <span
                              className="remove-wis ms-1 "
                              onClick={() => ClearFavItem(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i className="fa-regular fa-trash-can text-danger"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-end product-card-content">
                        <p className="mb-0">₹{(price * quantity).toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold"
                        onClick={() =>
                          handleAddToCart(
                            favQuantities[index],
                            item.variants[0].id
                          )
                        }
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                );
              })}
            <div className="wish-last py-3">
              <div className="d-flex justify-content-between product-card-content">
                <span className="fs-8 mb-0">
                  Empty Wishlist{" "}
                  <span
                    className="remove-wis ms-1 rounded-circl"
                    onClick={() => ClearAllFavItems()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-regular fa-trash-can text-danger"></i>
                  </span>
                </span>
                <span className="mb-0  d-flex">
                  Total:{" "}
                  <p className="ms-2 fw-bold"> ₹{totalAmount.toFixed(2)}</p>
                </span>
              </div>
            </div>
          </div>

          <div
            id="myWishlistContent"
            className="content-section d-block d-lg-none"
          >
            {favItems &&
              favItems.map((item, index) => {
                const price = Number(item.variants[0]?.price || 0);
                const quantity = favQuantities[index] || 1;

                return (
                  <div
                    className="wish-content py-3 border-bottom-2"
                    key={index}
                  >
                    <div className="row align-items-center">
                      {/* Left: Product Image (Small) */}
                      <div className="col-3 col-md-2">
                        {item.image ? (
                          <img
                            src={item.image}
                            className="img-fluid rounded"
                            alt={item.name}
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <div
                            className="img-fluid w-100 d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "#f0f0f0",
                              height: "80px",
                              fontSize: "24px",
                              color: "#333",
                            }}
                          >
                            {item.name?.charAt(0).toUpperCase() || "N"}
                          </div>
                        )}
                      </div>

                      {/* Right: Product Details */}
                      <div className="col-9 col-md-10">
                        <h6 className="fw-bold mb-1">
                          {item.name.length > 25
                            ? item.name.substring(0, 25) + "..."
                            : item.name}
                        </h6>

                        {/* Ratings */}
                        <div className="wish-ratings text-secondary small">
                          {[...Array(5)].map((_, idx) => (
                            <span
                              key={idx}
                              style={{
                                color:
                                  idx < Math.round(Number(item.rating))
                                    ? "gold"
                                    : "lightgray",
                              }}
                            >
                              ★
                            </span>
                          ))}
                          <span className="text-muted ms-1">
                            ({item.no_of_ratings || 0})
                          </span>
                        </div>

                        {/* Price & Quantity Controls */}
                        <div
                          className="d-flex align-items-center justify-content-between mt-2"
                          style={{ minWidth: "100%" }}
                        >
                          {/* Price (Fixed Width) */}
                          <p
                            className="mb-0 fw-bold text-dark"
                            style={{ width: "70px", textAlign: "center" }}
                          >
                            ₹{price}
                          </p>

                          {/* Quantity Controls (Fixed Width) */}
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "120px", justifyContent: "center" }}
                          >
                            <button
                              className="btn btn-outline-secondary border-0 p-1 fs-5"
                              onClick={() => handleFavDecrement(index)}
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "18px",
                              }}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="form-control text-center mx-1 border-0"
                              style={{
                                width: "40px",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                              value={quantity}
                              readOnly
                            />
                            <button
                              className="btn btn-outline-secondary border-0 p-1 fs-5"
                              onClick={() => handleFavIncrement(index)}
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "18px",
                              }}
                            >
                              +
                            </button>
                          </div>

                          <p
                            className="text-danger"
                            style={{
                              width: "100px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className="fa-solid fa-cart-plus text-info"
                              onClick={() =>
                                handleAddToCart(
                                  favQuantities[index],
                                  item.variants[0].id
                                )
                              }
                            ></i>
                            <i
                              className="fa-solid fa-trash-can ms-3"
                              onClick={() => ClearFavItem(item.id)}
                            ></i>
                          </p>
                        </div>

                        {/* Error Message for Quantity */}
                        {favErrorMessage && favErrorMessage[index] && (
                          <p
                            className="text-danger mt-2"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {favErrorMessage[index]}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* Wishlist Summary */}
            <div className="wish-last py-3">
              <div className="d-flex justify-content-between">
                <p
                  className="fs-8 mb-0 text-danger"
                  onClick={() => ClearAllFavItems()}
                  style={{ cursor: "pointer" }}
                >
                  Empty Wishlist ✖
                </p>
                <p className="mb-0 fw-bold">Total ₹{totalAmount.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cart Items */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRightCart"
        aria-labelledby="offcanvasRightLabel"
        style={{ width }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">MY CART</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div
            id="myWishlistContent"
            className="content-section d-none d-lg-block"
          >
            <div className="wish-head pb-3 border-bottom-2">
              <div className="row">
                <div className="col-5">
                  <p className="mb-0">Product</p>
                </div>
                <div className="col-2">
                  <p className="mb-0">Price</p>
                </div>
                <div className="col-3">
                  <p className="mb-0">Quantity</p>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <p className="mb-0">Total</p>
                </div>
              </div>
            </div>
            {cartErrorMessage && (
              <p className="alert alert-danger sticky-top">
                {cartErrorMessage}
              </p>
            )}
            {cartItems &&
              cartItems?.map((item, index) => {
                const price = item.special_price || 0;
                const quantity = cartQuantities[index] || 1;
                const getPrice = (item) =>
                  item?.is_prices_inclusive_tax == 1
                    ? Number(item.net_amount) + Number(item.tax_amount)
                    : item.net_amount;
                return (
                  <div
                    className="wish-content py-3 border-bottom-2"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-5">
                        <div className="wish-details row align-items-center my-2">
                          <div className="col-12 col-lg-4 my-2">
                            <img
                              src={item.image}
                              className="img-fluid w-100"
                              alt={`${item.name}`}
                            />
                          </div>
                          <div className="col-12 col-lg-8 ps-lg-4 my-2">
                            <h6 className="fw-bold">{item.name}</h6>
                            <div className="wish-ratings text-secondary">
                              <span>
                                {[...Array(5)].map((_, idx) => (
                                  <span
                                    key={idx}
                                    style={{
                                      color:
                                        idx < Math.round(Number(item.rating))
                                          ? "gold"
                                          : "lightgray",
                                    }}
                                  >
                                    ★
                                  </span>
                                ))}
                              </span>
                              <span className="text-muted">
                                ({item.no_of_ratings || 0})
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center product-card-content">
                        <p className="mb-0">₹{getPrice(item)}</p>
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div>
                          <div
                            className="d-flex align-items-center px-md-1 border-secondary"
                            style={{ height: "2rem" }}
                          >
                            <button
                              className="btn btn-outline-secondary decrement-btn border-0 p-1 fs-4"
                              onClick={() => handleCartDecrement(index)}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="form-control text-center mx-1 quantity-input border-0"
                              style={{ width: "40px", height: "1.5rem" }}
                              value={quantity}
                              readOnly
                            />
                            <button
                              className="btn btn-outline-secondary increment-btn border-0 p-1 py- fs-4"
                              onClick={() => handleCartIncrement(index)}
                            >
                              +
                            </button>
                          </div>
                          <div
                            className="btn fs-8 mb-0 mt-3 d-flex align-items-center m-0 p-0"
                            onClick={() =>
                              ClearCartItem(item.product_variant_id)
                            }
                          >
                            <p className="m-0 p-0"> Remove Item</p>
                            <i className="fa-regular fa-trash-can text-danger ms-1"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex align-items-center justify-content-end product-card-content">
                        <p className="mb-0">
                          ₹
                          {Number(
                            (price * quantity).toFixed(2)
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="text-end mt-2">
              <button
                type="button"
                className="fs-8 border-0 text-white bg-secondary px-3 py-1 rounded-pill fw-bold"
                onClick={handleCheckout}
              >
                Check Out
              </button>
            </div>
            <div className="wish-last py-3">
              <div className="d-flex justify-content-between product-card-content">
                <span className="fs-8 mb-0">
                  Empty Cart
                  <span
                    className="remove-wis ms-1 rounded-circl"
                    onClick={() => ClearAllCartItems()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa-regular fa-trash-can text-danger"></i>
                  </span>
                </span>
                <apan className="mb-0 d-flex">
                  Total:{" "}
                  <p className="fw-bold ms-2">₹{cartTotalAmount.toFixed(2)}</p>
                </apan>
              </div>
            </div>
          </div>

          <div id="myCartContent" className="content-section d-block d-lg-none">
            <h2 className="mb-4 text-center">MY CART</h2>
            <hr />
            {cartItems &&
              cartItems.map((item, index) => {
                const price = item.price || 0;
                const quantity = cartQuantities[index] || 1;
                return (
                  <div
                    className="wish-content py-3 border-bottom-2"
                    key={index}
                  >
                    <div className="row align-items-center">
                      <div className="col-3 col-md-2">
                        <img
                          src={item.image}
                          className="img-fluid rounded product-img"
                          alt={item.name}
                          style={{
                            width: "80px",
                            height: "80px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div className="col-9 col-md-10">
                        <h6 className="fw-bold mb-1">
                          {item.name.length > 25
                            ? item.name.substring(0, 25) + "..."
                            : item.name}
                        </h6>
                        <div className="wish-ratings text-secondary small">
                          {[...Array(5)].map((_, idx) => (
                            <span
                              key={idx}
                              style={{
                                color:
                                  idx < Math.round(Number(item.rating))
                                    ? "gold"
                                    : "lightgray",
                              }}
                            >
                              ★
                            </span>
                          ))}
                          <span className="text-muted ms-1">
                            ({item.no_of_ratings || 0})
                          </span>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-between mt-2"
                          style={{ minWidth: "100%" }}
                        >
                          <p
                            className="mb-0 fw-bold"
                            style={{ width: "70px", textAlign: "center" }}
                          >
                            ₹{price}
                          </p>
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "120px", justifyContent: "center" }}
                          >
                            <button
                              className="btn btn-outline-secondary border-0 p-1 fs-5"
                              onClick={() => handleCartDecrement(index)}
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "18px",
                              }}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              className="form-control text-center mx-1 border-0"
                              style={{
                                width: "50px",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                              value={quantity}
                              readOnly
                            />
                            <button
                              className="btn btn-outline-secondary border-0 p-1 fs-5"
                              onClick={() => handleCartIncrement(index)}
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "18px",
                              }}
                            >
                              +
                            </button>
                          </div>
                          <p
                            className="btn text-danger"
                            onClick={() =>
                              ClearCartItem(item.product_variant_id)
                            }
                            style={{
                              width: "40px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="wish-last py-3">
                            <div className="d-flex justify-content-between">
                                <p
                                    className="fs-8 mb-0 text-danger"
                                    onClick={() => ClearAllCartItems()}
                                    style={{ cursor: "pointer" }}
                                >
                                    Empty Cart ✖
                                </p>
                                <p className="mb-0 fw-bold">Total ₹{cartTotalAmount.toFixed(2)}</p>
                            </div>
                        </div> */}

            <div className="text-center text-md-end my-3">
              <button
                className="border-0 bg-secondary text-white px-4 py-2 fw-bold w-100 w-md-auto"
                onClick={handleCheckout}
              >
                CHECK OUT
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Transactions */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRightTrans"
        aria-labelledby="offcanvasRightLabel"
        style={{ width }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">MY TRANSACTIONS</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  {/* <th>Order Id</th> */}
                  <th>Date</th>
                  <th>Amount(Rs.)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.length > 0 &&
                  transactions.map((item, index) => (
                    <tr key={index}>
                      <td style={{ fontFamily: "monospace" }}>{index + 1}</td>
                      <td style={{ fontFamily: "monospace" }}>
                        {formatDate(item.transaction_date)}
                      </td>
                      <td style={{ fontFamily: "monospace" }}>{item.amount}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Sidebar for mobile devices */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasLeftSidebar"
        aria-labelledby="offcanvasRightLabel"
        style={{ minWidth: "90%", maxWidth: "90%" }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Categories</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <nav style={navListStyle}>
            {/* <Link  style={navItemStyle}
                            to='/products/0/ALL/0'
                        >
                            <span style={navIconStyle}><i className="fa-solid fa-globe text-secondary"></i></span> ALL
                        </Link> */}
            <a style={navItemStyle} href="/products/0/ALL/0">
              <span style={navIconStyle}>
                <i className="fa-solid fa-globe text-secondary"></i>
              </span>
              ALL
            </a>
            {allCategories &&
              allCategories.map((item, index) => (
                // <Link
                //     key={index}
                //     style={navItemStyle}
                //    to={`/products/${item.id}/NoTag/0`}
                // >
                //     <span style={navIconStyle}><i className="fa-solid fa-arrow-right text-secondary"></i></span> {item.name}
                // </Link>
                <a
                  key={index}
                  style={navItemStyle}
                  href={`/products/${item.id}/NoTag/0`}
                >
                  <span style={navIconStyle}>
                    <i className="fa-solid fa-arrow-right text-secondary"></i>
                  </span>
                  {item.name}
                </a>
              ))}
          </nav>
        </div>
      </div>
      <SignIn />
    </div>
  );
};

export default Base;
