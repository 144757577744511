import React, { useState, useEffect } from "react";
import Base from "./../Config/Base";
// import ProductImg from '../../Assests/Images/Purple Majesty.png';
import axios from "axios";
import { BASE_API_URL, JWT_TOKEN_POST } from "../Config/Config";
import { Link, useNavigate } from "react-router-dom";
import AddToFavorite from "../Favorites/AddFavorite";
import AddToCart from "../Cart/AddCart";
import { useParams } from "react-router-dom";
import SignIn from "../Auth/Signin";

export default function Products() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { CategoryId, TagName, Search } = useParams();

    const [userData, setUserData] = useState([]);
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(12);
    const [selectedFavProdId, setSelectedFavProdId] = useState("");
    const [selectedCartProdId, setSelectedCartProdId] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [maxPrice, setMaxPrice] = useState(13200);
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState(null);
    const [selectedCartProdMinOrdQty, setSelectedCartProdMinOrdQty] = useState('');

    const navigate = useNavigate();
    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        const storedUserData = sessionStorage.getItem("UserData");
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const formData = new FormData();
                formData.append("id", CategoryId);

                const response = await axios.post(
                    `${BASE_API_URL}get_categories`,
                    formData,
                    {
                        headers: {
                            Authorization: `${JWT_TOKEN_POST}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                setSubCategories(response.data.data[0].children);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCategories();
    }, [CategoryId]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.post(`${BASE_API_URL}get_brands_data`, {});
                setBrands(response.data.data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchBrands();
    }, [CategoryId]);

    // useEffect(() => {
    //   const fetchProducts = async () => {
    //     try {
    //       const formDataCategory = new FormData();
    //       const formDataTag = new FormData();
    //       const formDataSearch = new FormData();

    //       if (CategoryId !== "0") {
    //         formDataCategory.append("category_id", CategoryId);
    //       }
    //       if (TagName !== "ALL" && CategoryId === "0" && Search === "0") {
    //         formDataTag.append("tags", TagName);
    //       }
    //       if (TagName !== "ALL" && CategoryId === "0" && Search === "1") {
    //         formDataSearch.append("search", Search);
    //       }

    //       formDataTag.append("limit", "10000000");
    //       formDataCategory.append("limit", "10000000");
    //       formDataSearch.append("limit", "10000000");

    //       let finalProducts = [];

    //       if (CategoryId !== "0" && TagName === "ALL") {
    //         const categoryResponse = await axios.post(
    //           `${BASE_API_URL}get_products`,
    //           formDataCategory,
    //           {
    //             headers: { "Content-Type": "multipart/form-data" },
    //           }
    //         );
    //         finalProducts = categoryResponse.data.data || [];
    //       } else if (CategoryId === "0" && TagName !== "ALL") {
    //         const tagResponse = await axios.post(
    //           `${BASE_API_URL}get_products`,
    //           formDataTag,
    //           {
    //             headers: { "Content-Type": "multipart/form-data" },
    //           }
    //         );
    //         finalProducts = tagResponse.data.data || [];
    //       } else if (CategoryId !== "0" && TagName !== "ALL") {
    //         const [categoryResponse, tagResponse] = await Promise.all([
    //           axios.post(`${BASE_API_URL}get_products`, formDataCategory, {
    //             headers: { "Content-Type": "multipart/form-data" },
    //           }),
    //           axios.post(`${BASE_API_URL}get_products`, formDataTag, {
    //             headers: { "Content-Type": "multipart/form-data" },
    //           }),
    //         ]);

    //         const categoryProducts = categoryResponse.data.data || [];
    //         const tagProducts = tagResponse.data.data || [];

    //         const productMap = new Map();
    //         categoryProducts.forEach((product) =>
    //           productMap.set(product.id, product)
    //         );
    //         tagProducts.forEach((product) => productMap.set(product.id, product));

    //         finalProducts = Array.from(productMap.values());
    //       } else {
    //         const categoryResponse = await axios.post(
    //           `${BASE_API_URL}get_products`,
    //           formDataCategory,
    //           {
    //             headers: { "Content-Type": "multipart/form-data" },
    //           }
    //         );
    //         finalProducts = categoryResponse.data.data || [];
    //       }

    //       setProducts(finalProducts);
    //       setAllProducts(finalProducts);
    //     } catch (err) {
    //       console.error("Error fetching products:", err);
    //     }
    //   };

    //   fetchProducts();
    // }, [CategoryId, TagName, Search]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const formDataCategory = new FormData();
                const formDataTag = new FormData();
                const formDataSearch = new FormData();
                const formDataAllProducts = new FormData();
                // console.log(TagName, CategoryId)

                formDataAllProducts.append("limit", "10000000");

                if (CategoryId !== "0" && TagName === "NoTag") {
                    // console.log('cat prods fecthing')
                    formDataCategory.append("category_id", CategoryId);
                }
                if (TagName !== "ALL" && TagName !== "NoTag") {
                    formDataTag.append("tags", TagName);
                }
                if (TagName !== "ALL" && TagName !== "NoTag" && CategoryId !== 0) {
                    formDataTag.append("tags", TagName);
                    formDataCategory.append("category_id", CategoryId);
                }

                if (Search === "1" && TagName !== "ALL") {
                    formDataSearch.append("search", TagName);
                }

                formDataTag.append("limit", "10000000");
                formDataCategory.append("limit", "10000000");
                formDataSearch.append("limit", "10000000");

                // console.log("TagName:", TagName);
                // console.log("CategoryId:", CategoryId);
                // console.log("Search:", Search);
                // console.log("FormDataTag:", Object.fromEntries(formDataTag.entries()));

                let finalProducts = [];

                if (CategoryId === "0" && TagName === "ALL" && Search === "0") {
                    const allProductsResponse = await axios.post(
                        `${BASE_API_URL}get_products`,
                        formDataAllProducts,
                        { headers: { "Content-Type": "multipart/form-data" } }
                    );
                    finalProducts = allProductsResponse.data.data || [];
                }

                else if (CategoryId !== "0" && (TagName !== "ALL" || TagName === "NoTag") && Search === "0") {
                    const [categoryResponse, tagResponse] = await Promise.all([
                        axios.post(`${BASE_API_URL}get_products`, formDataCategory, {
                            headers: { "Content-Type": "multipart/form-data" },
                        }),
                        axios.post(`${BASE_API_URL}get_products`, formDataTag, {
                          headers: { "Content-Type": "multipart/form-data" },
                        }),
                    ]);

                    const categoryProducts = categoryResponse.data.data || [];
                    const tagProducts = tagResponse.data.data || [];

                    const productMap = new Map();
                    categoryProducts.forEach((product) => productMap.set(product.id, product));
                    tagProducts.forEach((product) => productMap.set(product.id, product));

                    finalProducts = Array.from(productMap.values());
                }

                else if (CategoryId === "0" && TagName !== "ALL" && TagName !== "NoTag" && Search === "0") {
                    const [tagResponse] = await Promise.all([
                        axios.post(`${BASE_API_URL}get_products`, formDataTag, {
                            headers: { "Content-Type": "multipart/form-data" },
                        }),
                    ]);

                    const tagProducts = tagResponse.data.data || [];

                    const productMap = new Map();
                    tagProducts.forEach((product) => productMap.set(product.id, product));

                    finalProducts = Array.from(productMap.values());
                }

                else if (CategoryId === "0" && TagName !== "ALL" && Search === "1") {
                    const [tagResponse, searchResponse] = await Promise.all([
                        axios.post(`${BASE_API_URL}get_products`, formDataTag, {
                            headers: { "Content-Type": "multipart/form-data" },
                        }),
                        axios.post(`${BASE_API_URL}get_products`, formDataSearch, {
                            headers: { "Content-Type": "multipart/form-data" },
                        }),
                    ]);

                    const tagProducts = tagResponse.data.data || [];
                    const searchProducts = searchResponse.data.data || [];

                    const productMap = new Map();
                    tagProducts.forEach((product) => productMap.set(product.id, product));
                    searchProducts.forEach((product) => productMap.set(product.id, product));

                    finalProducts = Array.from(productMap.values());
                }

                // else if (TagName !== "ALL" && TagName !== "NoTag" && CategoryId !== 0 && Search === "0") {
                //     const [tagResponse, catResponse] = await Promise.all([
                //         axios.post(`${BASE_API_URL}get_products`, formDataTag, {
                //             headers: { "Content-Type": "multipart/form-data" },
                //         }),
                //         axios.post(`${BASE_API_URL}get_products`, formDataCategory, {
                //             headers: { "Content-Type": "multipart/form-data" },
                //         }),
                //     ]);

                //     const tagProducts = tagResponse.data.data || [];
                //     const catProducts = catResponse.data.data || [];

                //     const productMap = new Map();
                //     tagProducts.forEach((product) => productMap.set(product.id, product));
                //     catProducts.forEach((product) => productMap.set(product.id, product));

                //     finalProducts = Array.from(productMap.values());
                // }

                setProducts(finalProducts);
                setAllProducts(finalProducts);

            } catch (err) {
                console.error("Error fetching products:", err);
            }
        };

        fetchProducts();
    }, [CategoryId, TagName, Search]);


    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts?.slice(
        indexOfFirstProduct,
        indexOfLastProduct
    );
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const updateItemsPerPage = (event) => {
        setProductsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const renderPagination = () => {
        const pages = [];
        const displayRange = 3;

        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - displayRange && i <= currentPage + displayRange)
            ) {
                pages.push(
                    <li
                        className={`page-item ${currentPage === i ? "active" : ""}`}
                        key={i}
                    >
                        <button className="page-link" onClick={() => changePage(i)}>
                            {i}
                        </button>
                    </li>
                );
            } else if (pages[pages.length - 1] !== "...") {
                pages.push(
                    <li key={`dots-${i}`} className="page-item disabled">
                        ...
                    </li>
                );
            }
        }
        return pages;
    };

    const ProductDetailsClick = (productId) => {
        navigate(`/product-details/${productId}`);
    };

    const handleClickFavIcon = (id) => {
        setSelectedFavProdId(id);
    };

    const handleClickCartBtn = (id, minOrdQty) => {
        setSelectedCartProdId(id);
        setSelectedCartProdMinOrdQty(minOrdQty);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const toggleDropdown3 = () => {
        setIsOpen3(!isOpen3);
    };

    const toggleDropdown4 = () => {
        setIsOpen4(!isOpen4);
    };

    const handleSort = (event) => {
        const { dataset, checked } = event.target;
        setFilters((prev) => ({
            ...prev,
            [dataset.sort]: checked,
        }));
    };

    const [filters, setFilters] = useState({
        topRated: false,
        newest: false,
    });

    // const handleSubCategoryClick = (id, name) => {
    //     setSelectedSubCategoryName(name);
    //     const filtered = allProducts.filter(
    //         (product) => product.category_id === id
    //     );

    //     if (filtered.length > 0) {
    //         setFilteredProducts(filtered);
    //     } else {
    //         setFilteredProducts([]);
    //     }
    // };

    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    const handleSubCategoryClick = async (id, name) => {
        let filteredProducts1 = [...allProducts]; 
        setSelectedSubCategory(id)
        setSelectedSubCategoryName(name);
        console.log(id, name);
        console.log(filteredProducts1);
        const filteredProductsres = filteredProducts1.filter(
            (product) => product.tags.includes(name)
        );
        
        console.log(filteredProductsres);

        setFilteredProducts(filteredProductsres);
    
        // const formDataTag = new FormData();
        // formDataTag.append("tags", name);
        // formDataTag.append("limit", "10000000");
    
        // try {
        //     const response = await axios.post(
        //         `${BASE_API_URL}get_products`,
        //         formDataTag,
        //         {
        //             headers: { "Content-Type": "multipart/form-data" },
        //         }
        //     );
        //     console.log(filteredProducts);

    
        //     if (response.data && response.data.data.length > 0) {
        //         // Filter products by category_id
        //         // const filteredProducts = response.data.data.filter(
        //         //     (product) => product.category_id === CategoryId
        //         // );
                
        //     } else {
        //         setFilteredProducts([]);
        //     }
        // } catch (error) {
        //     console.error("Error fetching products:", error);
        //     setFilteredProducts([]);
        // }
        if (window.innerWidth <= 768) {
            setIsOpen(false);
        }
    };

    // useEffect(() => {
    //     let sortedProducts = [...products];

    //     if (selectedSubCategoryId) {
    //         sortedProducts = sortedProducts.filter(
    //             (product) => product.category_id === selectedSubCategoryId
    //         );
    //     }
    //     if (filters.newest) {
    //         sortedProducts.sort(
    //             (a, b) =>
    //                 new Date(b.variants[0].date_added) -
    //                 new Date(a.variants[0].date_added)
    //         );
    //     }
    //     if (filters.topRated) {
    //         sortedProducts.sort(
    //             (a, b) => parseFloat(b.rating) - parseFloat(a.rating)
    //         );
    //     }
    //     if (filters.priceHighLow) {
    //         sortedProducts.sort(
    //             (a, b) =>
    //                 parseFloat(b.min_max_price.special_price) -
    //                 parseFloat(a.min_max_price.special_price)
    //         );
    //     }
    //     if (filters.priceLowHigh) {
    //         sortedProducts.sort(
    //             (a, b) =>
    //                 parseFloat(a.min_max_price.special_price) -
    //                 parseFloat(b.min_max_price.special_price)
    //         );
    //     }
    //     setCurrentPage(1);
    //     setProducts(sortedProducts);
    // }, [filters, selectedSubCategoryId, maxPrice]);

    useEffect(() => {
        let filteredProducts = [...allProducts]; 

        if (maxPrice) {
        
            filteredProducts = filteredProducts.filter((product) => {
                const specialPrice = Number(product.min_max_price.special_price); 
                return specialPrice > 0 && specialPrice <= maxPrice; 
            });
        
        }

        if (filters.newest) {
            filteredProducts.sort(
                (a, b) =>
                    new Date(b.variants[0].date_added) - 
                    new Date(a.variants[0].date_added)
            );
        }
        if (filters.topRated) {
            filteredProducts.sort(
                (a, b) => parseFloat(b.rating) - parseFloat(a.rating)
            );
        }
        if (filters.priceHighLow) {
            filteredProducts.sort(
                (a, b) =>
                    parseFloat(b.min_max_price.special_price) - 
                    parseFloat(a.min_max_price.special_price)
            );
        }
        if (filters.priceLowHigh) {
            filteredProducts.sort(
                (a, b) =>
                    parseFloat(a.min_max_price.special_price) - 
                    parseFloat(b.min_max_price.special_price)
            );
        }

        // ✅ Reset Pagination and Set Products
        setCurrentPage(1);
        setProducts(filteredProducts);
    }, [filters, selectedSubCategoryId, maxPrice, allProducts]);

    const handleBrandSelection = (event) => {
        const { checked, dataset } = event.target;
        const brandId = dataset.brandid;

        setSelectedBrands((prev) => {
            if (checked) {
                return [...prev, brandId];
            } else {
                return prev.filter((id) => id !== brandId);
            }
        });
    };

    useEffect(() => {
        if (selectedBrands.length > 0) {
            const filteredProducts = products.filter((product) =>
                selectedBrands.includes(product.brand_id)
            );
            setProducts(filteredProducts);
        } else {
            setProducts(allProducts);
        }
    }, [selectedBrands]);

    const handlePriceChange = (event) => {
        setMaxPrice(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        const updatedProducts = products.filter(
            (product) => product.variants[0].price <= maxPrice
        );
        setFilteredProducts(updatedProducts);
    }, [maxPrice, products]);

    const handleCatProdsClick = () => {
        setFilteredProducts(allProducts);
        setSelectedSubCategoryName("");
    };

    return (
        <Base>
            <div className="container my">
                <div className=" mb-3">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a className="text-decoration-none text-dark" href="#">
                                        Home
                                    </a>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                    onClick={() => handleCatProdsClick()}
                                    style={{ cursor: "pointer" }}
                                >
                                    {allProducts[0]?.category_name}
                                </li>
                                {selectedSubCategoryName && (
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {selectedSubCategoryName}
                                    </li>
                                )}
                            </ol>
                        </nav>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center font-rock">
                            <h1
                                className="text-uppercase me-3 fs-sm-1"
                                onClick={() => handleCatProdsClick()}
                                style={{ cursor: "pointer" }}
                            >
                                {/* {allProducts[0]?.category_name} */}
                                {TagName}
                            </h1>
                            <span
                                className="badge bg-light text-dark fs-6 fw-light"
                                id="total-items"
                            >
                             <span style={{ fontFamily: 'monospace' }}> {filteredProducts?.length}</span> items
                            </span>
                        </div>

                        <div className="d-md-flex d-none align-items-center ">
                            <label for="items-per-page" className="me-2">
                                Show:
                            </label>
                            <select
                                id="items-per-page"
                                className="form-select form-select-sm"
                                onChange={updateItemsPerPage}
                                value={productsPerPage}
                                style={{ fontFamily: 'monospace' }}
                            >
                                <option value="12" selected="">
                                    12
                                </option>
                                <option value="24">24</option>
                                <option value="48">48</option>
                                <option value="96">96</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="d-lg-none mb-3 text-center py-2 border-block-2">
                            <button
                                id="filter-toggle"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                className="border-0 bg-white text-uppercase font-rock filter-button"
                            >
                                FILTER
                            </button>
                        </div>
                        <div
                            className=" filter-sectio border-right-5 collapse d-lg-block"
                            id="collapseExample"
                        >
                            <div id="filter-overla" className="filter-overla"></div>

                            <div className="dropdown-container">
                                <h5
                                    className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown}
                                    style={{ cursor: "pointer" }}
                                >
                                Sub-category
                                <span className="dropdown-toggle-icon">
                                    <i
                                        className={`fas ${
                                            isOpen ? "fa-angle-up" : "fa-angle-down"
                                        }`}
                                    ></i>
                                </span>
                                </h5>
                                {isOpen && (
                                    <ul className="dropdown-lit font-rock">
                                        {subCategories &&
                                        subCategories.map((item, index) => (
                                            <li key={index}>
                                                <span
                                                    onClick={() =>
                                                        handleSubCategoryClick(item.id, item.name)
                                                    }
                                                    style={{ cursor: "pointer" }}
                                                    className={selectedSubCategory === item.id ? "bg-primary text-white" : ""}
                                                >
                                                    {item.name}
                                                </span>
                                                {item.children && item.children.length > 0 && (
                                                    <ul className="nested-dropdown">
                                                        {item.children.map((child, childIndex) => (
                                                            <li
                                                                key={childIndex}
                                                                onClick={() =>
                                                                    handleSubCategoryClick(child.id, child.name)
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                {child.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                
                            </div>
                            <style>
                                {`
                                    .no-bullets {
                                        list-style: none; /* Removes the bullets */
                                        padding: 0; /* Removes default padding */
                                        margin: 0; /* Removes default margin */
                                    }
                                `}
                            </style>

                            <div className="dropdown-container">
                                <h5
                                    className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown1}
                                >
                                    Sort By
                                    <span className="dropdown-toggle-icon">
                                        <i className="fas fa-angle-down"></i>
                                    </span>
                                </h5>
                                {isOpen1 && (
                                    <ul className="dropdown-lis font-rock no-bullets">
                                        <li>
                                            <input
                                                type="checkbox"
                                                data-sort="topRated"
                                                checked={filters.topRated}
                                                onChange={handleSort}
                                            />{" "}
                                            Top Rated
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                data-sort="newest"
                                                checked={filters.newest}
                                                onChange={handleSort}
                                            />{" "}
                                            Newest First
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                data-sort="priceLowHigh"
                                                checked={filters.priceLowHigh}
                                                onChange={handleSort}
                                            />{" "}
                                            Price: Low to High
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                data-sort="priceHighLow"
                                                checked={filters.priceHighLow}
                                                onChange={handleSort}
                                            />{" "}
                                            Price: High to Low
                                        </li>
                                    </ul>
                                )}
                            </div>

                            <div className="dropdown-container">
                                <h5
                                    className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown2}
                                >
                                    Brands
                                    <span className="dropdown-toggle-icon">
                                        <i className="fas fa-angle-down"></i>
                                    </span>
                                </h5>
                                {isOpen2 && (
                                    <ul className="dropdown-lis font-rock no-bullets">
                                        {brands &&
                                            brands?.map((item, index) => (
                                                <li key={index}>
                                                    <input
                                                        type="checkbox"
                                                        data-brandid={item.id}
                                                        onChange={handleBrandSelection}
                                                    />{" "}
                                                    {item.name}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>

                            <div className="dropdown-container">
                                <h5
                                    className="dropdown-header font-rock fs-6 fw-500 text-dark d-flex justify-content-between"
                                    onClick={toggleDropdown4}
                                >
                                    Price
                                    <span className="dropdown-toggle-icon">
                                        <i className="fas fa-angle-down"></i>
                                    </span>
                                </h5>
                                {isOpen4 && (
                                    <div className="dropdown-lis font-rock">
                                        <div className="price-range-container">
                                            <input
                                                type="range"
                                                id="price-range"
                                                min="10"
                                                max="13200"
                                                step="1"
                                                value={maxPrice}
                                                onChange={handlePriceChange}
                                                className="form-range"
                                            />
                                            <div className="price-values">
                                                <span>Price Up To: ₹{maxPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9 p-3">
                        <div className="row pt-3" id="product-container">
                            {currentProducts && currentProducts.length > 0 ? (
                                currentProducts.map((item, index) => (
                                    <div
                                        className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4"
                                        key={index}
                                    >
                                        <style>
                                            {`
                                                .product-card {
                                                    height: auto; /* Default for all devices */
                                                }
                                                @media (min-width: 768px) {
                                                    .product-card {
                                                    height: 29rem; /* Medium and larger devices */
                                                    }
                                                }
                                            `}
                                        </style>
                                        <div className="product-card bg-light position-relative">
                                            <div className="product-fav">
                                                <i
                                                    className={`fa-regular fa-heart ${userData.id ? "d-none" : "d-block"}`}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modal-signin"
                                                ></i>
                                                <i
                                                    className={`fa-regular fa-heart ${userData.id ? "d-block" : "d-none"}`}
                                                    onClick={() => handleClickFavIcon(item.id)}
                                                ></i>
                                            </div>
                                            <div className="product-fav mt-5">
                                                <Link to={`/product-details/${item.id}`}>
                                                    <i className="fa-regular fa-eye text-dark"></i>
                                                </Link>
                                            </div>
                                            <img
                                                src={item.image}
                                                className="img-fluid rounded-top"
                                                alt="Purple Majesty"
                                                onClick={() => ProductDetailsClick(item.id)}
                                            />
                                            <div className="product-content p-3">
                                                <h5
                                                    className="mt-3 text-center"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => ProductDetailsClick(item.id)}
                                                    title={`${item.name}`}
                                                >
                                                    {item.name.length > 19
                                                        ? `${item.name.substring(0, 16)}...`
                                                        : item.name}
                                                </h5>
                                                <div className="text-center product-card-content d-flex justify-content-center align-items-center">
                                                    <span className="product-price fs-5 fw-bold text-dark">
                                                        <p>₹ {Number(item.min_max_price.special_price).toFixed(2)}</p>
                                                    </span>
                                                    <span className="product-discount text-danger ms-2 fs-6">
                                                        <p>₹{Number(item.min_max_price.max_price).toFixed(2)}</p>
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    <a>
                                                        <button
                                                            className={`btn text-white d-flex m-auto bg-secondary w-80 ${userData.id ? "d-none" : "d-block"}`}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#modal-signin"
                                                        >
                                                            Add To Cart
                                                        </button>
                                                        <button
                                                            className={`btn text-white d-flex m-auto bg-secondary w-80 ${userData.id ? "d-block" : "d-none"}`}
                                                            onClick={() =>
                                                                handleClickCartBtn(
                                                                    item.variants[0].statistics.product_variant_id,
                                                                    item.minimum_order_quantity
                                                                )
                                                            }
                                                        >
                                                            Add To Cart
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col-12 text-center">
                                    <p className="text-secondary fs-5 fw-bold">No products available for this category.</p>
                                </div>
                            )}

                        </div>

                        <nav>
                            <ul
                                className="pagination justify-content-center"
                                id="pagination-container"
                                style={{ fontFamily: 'monospace' }}
                            >
                                {renderPagination()}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <AddToFavorite productId={selectedFavProdId} />
            <AddToCart productVariantId={selectedCartProdId} qty={selectedCartProdMinOrdQty} />
            <SignIn />
        </Base>
    );
}
