
import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';


export default function OrderReceipt () {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { orderId } = useParams();
    const [orderDetails, setOrderDetails] = useState([]);

    const fetchOrderDetails = async () => {
        if (orderId) {
          try {
            const response = await axios.get(
              `http://192.168.1.62:5000/GetOrderDetails?orderId=${orderId}`
            );
            setOrderDetails(response.data.ResultData[0]);
            console.log(response.data.ResultData[0], "orderDetails data");
          } catch (error) {
            console.error("Error fetching alert data:", error);
          }
        }
    };

    useEffect(() => {
        fetchOrderDetails();
    }, [orderId]);

    function formatDate(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    

    return (
        <div className="container card shadow">
             <div className={`row`}>
                <div className="col-md-12 orders-section settings-tab-content">
                    <div className="mb-4 border-0 shadow-xl p-10">
                        <div className="card-header bg-white">
                            <div className="d-flex justify-content-between">
                                <div className="col d-flex justify-content-between">
                                    <p className="text-muted"> Order ID:<span className="fw-bold text-dark"> ORD-{orderDetails && orderDetails.id} </span></p>
                                    <p className="text-muted">
                                        Place On: <span className="fw-bold text-dark">
                                            {orderDetails && formatDate(orderDetails.date_added)}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="card-body">
                            <div >
                                <div className="media d-flex flex-sm-row mb-2">
                                    <div className="media-body">
                                        <h5 className="bold">{orderDetails.product_name}</h5>
                                        <p className="text-muted mb-0">Quantity: {orderDetails.quantity}</p>
                                        <h4 className="mt-3 mb-2 bold">
                                            <span className="mt-5"><i>₹</i></span> {orderDetails.price}
                                            <span className="text-danger mx-2 text-decoration-line-through">{orderDetails.main_price}</span>
                                        </h4>
                                    </div>
                                    <div className="align-self-center img-fluid ms-auto">
                                        <img
                                            src={`https://shop.giivv.com/${orderDetails.image}`}
                                            width="180"
                                            height="180"
                                            className="logo-fit rounded"
                                        />
                                    </div>
                                </div>

                                <section className="wrapper bg-light mb-2">
                                    <div className="container py-8">
                                        <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line" id="progressbar">
                                        {orderDetails.status?.length > 0 ? (
                                            JSON.parse(orderDetails.status).map((statusItem, statusIndex) => (
                                            <div className={`col-md-6 col-lg-2 ${statusIndex === 0 ? "active" : ""}`} key={statusIndex}>
                                                <span className="icon btn btn-circle btn-secondary pe-none mb-2">
                                                <span className="number" id={`step${statusIndex + 1}`}>{statusIndex + 1}</span>
                                                </span>
                                                <h6 className="mb-0 text-uppercase" style={{ textTransform: 'uppercase' }}>{statusItem[0]}</h6>
                                                <p className="mb-0">{statusItem[1]}</p>
                                            </div>
                                            ))
                                        ) : (
                                            <p>No status available</p>
                                        )}
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-6">
                                    <h6 className="h5">Shipping Details</h6>
                                    <hr className="" />
                                    <span>{orderDetails && orderDetails.username}</span> <br/>
                                    <span>{orderDetails && orderDetails.address}</span> <br/>
                                    <span>{orderDetails && orderDetails.mobile}</span> <br/>
                                    <span>{orderDetails && orderDetails.delivery_time}</span> <br/>
                                    <span>{orderDetails && orderDetails.delivery_date}</span> <br/>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="h5">Price Details</h6>
                                    <hr className="" />
                                    <div className="table-responsive">
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th>Total Order Price</th>
                                                    <td>+ ₹ {orderDetails && orderDetails.total}</td>
                                                </tr>
                                                <tr>
                                                    <th>Delivery Charge</th>
                                                    <td>+ ₹ {orderDetails && orderDetails.delivery_charge}</td>
                                                </tr>
                                                <tr className="d-none">
                                                    <th>Tax - (0%)</th>
                                                    <td>+ ₹ {orderDetails && orderDetails.total_tax_amount}</td>
                                                </tr>
                                                <tr>
                                                    <th>Wallet Used</th>
                                                    <td>- ₹ {orderDetails && orderDetails.wallet_balance}</td>
                                                </tr>
                                                <tr className="h6">
                                                    <th>Final Total</th>
                                                    <td>₹ {orderDetails && orderDetails.final_total}<span className="small text-muted"> via ({orderDetails && orderDetails.payment_method}) </span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}