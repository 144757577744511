import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { BASE_API_URL } from "../Config/Config";
import Base from "../Config/Base";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";


export default function ViewCart() {

    const navigate = useNavigate();

    const [userData, setUserData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [cartVariants, setCartVariants] = useState([]);
    const [cartQuantities, setCartQuantities] = useState([]);
    const [cartTotalAmount, setCartTotalAmount] = useState(0);
    const [cartErrorMessage, setCartErrorMessage] = useState(null);
    // const [selectedQuantity, setSelectedQuantity] = useState(1);


    useEffect(() => {
        const storedUserData = sessionStorage.getItem('UserData');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error("Error parsing storedUserData:", error);
            }
        }
    }, []);

    useEffect(() => {
        fetchCartItems();
        // window.location.reload();
    }, [userData]);
    
    const fetchCartItems = async () => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);

            const response = await axios.post(`${BASE_API_URL}get_user_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setCartItems(response.data.data);
            // console.log(response.data.data);
            setCartVariants(response.data.variant_id);
        } catch (err) {
            console.log('failed fetching fav items..!');
        }
    };

    const handleCartIncrement = (index) => {
        setCartQuantities((prev) => {
            const updated = [...prev];
            updated[index] += 1;
            calculateTotal(updated);
            if (updated[index] > cartItems[index].total_allowed_quantity) {
                setCartErrorMessage(
                    `You can only add up to ${cartItems[index].total_allowed_quantity} items for this product.`
                );
                updated[index] = cartItems[index].total_allowed_quantity
            }
            return updated;
        });
        cartItems[index].qty = cartQuantities[index];
    };

    const handleCartDecrement = (index) => {
        setCartQuantities((prev) => {
            const updated = [...prev];
            updated[index] = Math.max(1, updated[index] - 1);
    
            calculateTotal(updated);
    
            if (updated[index] <= cartItems[index]?.total_allowed_quantity) {
                setCartErrorMessage(null);
            }
            return updated;
        });

        cartItems[index].qty = cartQuantities[index];
    };
    
    const calculateTotal = (quantities) => {
        const total = (cartItems || []).reduce((sum, item, index) => {
            const price = Number(item.special_price || 0);
            const quantity = quantities[index] || 1;
            return sum + price * quantity;
        }, 0);
        setCartTotalAmount(total);
    };

    useEffect(() => {
        if (cartItems && cartItems.length > 0) {
            const initialQuantities = cartItems.map(item => Number(item.qty) || 1);
            setCartQuantities(initialQuantities);
            calculateTotal(initialQuantities);
        }
    }, [cartItems]);
    

    const ClearCartItem = async (id) => {
        try {
            const formData = new FormData();
            formData.append("user_id", userData.id);
            formData.append("product_variant_id", id);

            const response = await axios.post(`${BASE_API_URL}remove_from_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            fetchCartItems();
        } catch (err) {
            console.error('Failed fetching favorite items:', err);
        }
    };

    const ClearAllCartItems = async () => {
        try {
            const formData = new FormData();
            const variantIds = cartVariants.join(',');
            formData.append("user_id", userData.id);
            formData.append("product_variant_id", variantIds);

            const response = await axios.post(`${BASE_API_URL}remove_from_cart`, formData, {
                headers: {
                    Authorization: `Bearer ${userData.apikey}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            fetchCartItems();
            // console.log(response.data, 'cart.... data getting clear all');
        } catch (err) {
            console.error('Failed fetching favorite items:', err);
        }
    };

    // const handleAddToCart = async (qty, productVariantId) => {

    //     const url = `${BASE_API_URL}manage_cart`;
    //     if (!productVariantId) return;
        
    //     console.log(productVariantId, qty, 'id triggered in cart fun');
    //     try {
    //         const formData = new FormData();
    //         formData.append("user_id", userData.id);
    //         formData.append("qty", qty);
    //         formData.append("product_variant_id", productVariantId);
    
    //         const response = await fetch(url, {
    //             method: "POST",
    //             headers: {
    //                 Authorization: `Bearer ${userData.apikey}`,
    //             },
    //             body: formData,
    //         });
    //         console.log(response, 'response from api manage_cart');
    
    //         if (response.ok) {
    //             const data = await response.json();
    //             console.log("Added to favorites:", data);
    //             if (!data.error) {
    //                 alert("Product added to cart successfully!");
    //             }
    //             else {
    //                 alert("Failed to add product to cart.");
    //             }
    //         } else {
    //             console.error("Error adding to favorites:", response.statusText);
    //             alert("Failed to add product to cart.");
    //         }
    //     } catch (error) {
    //         console.error("Error adding to favorites:", error);
    //         alert("An error occurred while adding to cart.");
    //     }
    // };

    const handleCheckout = async () => {
        const userId = userData.id;
    
        const cartData = cartItems.map((item, index) => ({
            product_variant_id: item.product_variant_id,
            qty: cartQuantities[index] || 1,
            user_id: userId,
        }));
    
        try {
            const promises = cartData.map(async (data) => {
                const formData = new FormData();
                formData.append("product_variant_id", data.product_variant_id);
                formData.append("qty", data.qty);
                formData.append("user_id", data.user_id);
    
                const response = await axios.post(`${BASE_API_URL}manage_cart`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData.apikey}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
    
                return response.data;
            });
    
            const results = await Promise.all(promises);
            if (!results[0].error) {
                alert("Cart updated successfully! Redirecting to checkout...");
                navigate('/billing-details');
            }
    
        } catch (error) {
            console.error("Error updating cart:", error);
            alert("Failed to update the cart. Please try again.");
        }
    };
    
    return (
        <Base>
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 mt-5 bg-white">
                        <div className="cart-table-wrapper" style={{ marginTop: '-4rem' }}>
                            <div className="text-end">
                                <button name="clear_cart" id="clear_cart"
                                    className="btn btn-outline-secondary btn-sm rounded-pill text-danger mt-3 mb-4"
                                    onClick={() => ClearAllCartItems()}    
                                >Clear Cart</button>
                            </div>
                            {cartErrorMessage && <p className="alert alert-danger">{cartErrorMessage}</p>}
                            <div className="table-responsive d-none d-lg-block">
                                <table id="cart_item_table" className="table  table-cart-product shopping-cart">
                                    <thead>
                                        <tr>
                                            <th className="ps-0" width="290px">
                                                <div className="h6 mb-0 text-start">Product</div>
                                            </th>
                                            <th>
                                                <div className="h6 mb-0">Price</div>
                                            </th>
                                            <th>
                                                <div className="h6 mb-0">Tax(%)</div>
                                            </th>
                                            <th>
                                                <div className="h6 mb-0">Quantity</div>
                                            </th>
                                            <th>
                                                <div className="h6 mb-0">Subtotal</div>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems &&
                                            cartItems.map((item, index) => {
                                                const price = item.special_price || 0;
                                                const quantity = cartQuantities[index] || 1;
                                                const maxQuantity = item.allowedQuantity;
                                                const isError = quantity > maxQuantity;
                                                const getPrice = (item) => item?.is_prices_inclusive_tax == 1 ? Number(item.net_amount) + Number(item.tax_amount) : item.net_amount;

                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr className="cart-product-desc-list">
                                                            <td className="option text-start d-flex flex-row align-items-center ps-0" title={item.name}>
                                                                <figure className="rounded cart-img">
                                                                    <Link to={`/product-details/${item.product_details[0].id}`}>
                                                                        <img src={item.image} alt={item.name} className="image-fit rounded" style={{ width: '5rem' }} />
                                                                    </Link>
                                                                </figure>
                                                                <div className="w-100 ms-4">
                                                                    <h3 className="post-title h6 lh-xs mb-3">
                                                                        <Link to={`/product-details/${item.product_details[0].id}`} className="text-decoration-none text-dark"  target="_blank">
                                                                            {item.name}
                                                                        </Link>
                                                                    </h3>
                                                                </div>
                                                            </td>
                                                            <td className="product-card-content">
                                                                <p className="price mt-4">
                                                                    <span className="amount">₹{getPrice(item)}</span>
                                                                </p>
                                                            </td>
                                                            <td> <p className="mt-4">{item.tax_percentage || 'N/A'}</p></td>
                                                            <td className="item-quantity">
                                                                <div className="num-block skin-2 product-quantity mt-4" style={{ width: '5rem' }}>
                                                                    <div className="d-flex align-items-center border-secondary">
                                                                        <button
                                                                            className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-6"
                                                                            onClick={() => handleCartDecrement(index)}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control text-center mx-1 quantity-input border-0"
                                                                            style={{ width: "40px", height: '20px' }}
                                                                            value={quantity}
                                                                            readOnly
                                                                        />
                                                                        <button
                                                                            className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-6"
                                                                            onClick={() => handleCartIncrement(index)}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                    {isError && (
                                                                        <div className="text-danger mt-2">
                                                                            Quantity exceeds the allowed limit of {maxQuantity}.
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="text-center p-otal-price product-card-content">
                                                                 <p className="price mt-4"><span className="amount">₹{Number((price * quantity).toFixed(2)).toLocaleString()}</span></p>
                                                            </td>
                                                            <td title="Remove from Cart">
                                                                <i className="fa-regular fa-trash-can text-danger btn border-danger mt-4" onClick={() => ClearCartItem(item.id)}></i>
                                                            </td>
                                                        </tr>
                                                        {isError && (
                                                            <tr>
                                                                <td colSpan="6" className="text-center text-danger">
                                                                    Exceeds allowed quantity. Max allowed: {maxQuantity}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="cart-items-list d-block d-lg-none">
                                {cartItems && cartItems.map((item, index) => {
                                    const price = item.special_price || 0;
                                    const quantity = cartQuantities[index] || 1;
                                    const maxQuantity = item.allowedQuantity;
                                    const isError = quantity > maxQuantity;

                                    const getPrice = (item) =>
                                        item?.is_prices_inclusive_tax == 1
                                            ? Number(item.net_amount) + Number(item.tax_amount)
                                            : item.net_amount;
                                    return (
                                        <div className="cart-item d-flex align-items-center py-3 border-bottom" key={index}>
                                            <div className="cart-item-image me-3">
                                                <Link to={`/product-details/${item.product_details[0]?.id}`}>
                                                    <img
                                                        src={item.image}
                                                        alt={item.name}
                                                        className="rounded"
                                                        style={{ width: '4rem', height: '4rem', objectFit: 'cover' }}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="cart-item-details d-flex flex-grow-1 align-items-center justify-content-between">
                                                <div className="cart-item-info flex-grow-1">
                                                    <h6 className="fw-bold mb-1 text-truncate" style={{ maxWidth: '150px' }}>
                                                        <Link
                                                            to={`/product-details/${item.product_details[0]?.id}`}
                                                            className="text-decoration-none text-dark"
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    </h6>
                                                    <div className="text-muted" style={{ fontSize: '0.875rem' }}>
                                                        Tax: {item.tax_percentage || 'N/A'}%
                                                    </div>
                                                    <div className="fw-bold mt-1 product-card-content"><p>₹{getPrice(item)}</p></div>
                                                </div>
                                                <div className="cart-item-quantity d-flex align-items-center">
                                                    <button
                                                        className="btn btn-outline-secondary decrement-btn border-0 p-1 fs-6"
                                                        onClick={() => handleCartDecrement(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <input
                                                        type="text"
                                                        className="form-control text-center mx-1 border-0"
                                                        style={{ width: "40px" }}
                                                        value={quantity}
                                                        readOnly
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary increment-btn border-0 p-1 fs-6"
                                                        onClick={() => handleCartIncrement(index)}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <div className="cart-item-remove ms-3">
                                                    <i
                                                        className="fa-regular fa-trash-can text-danger"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => ClearCartItem(item.id)}
                                                    ></i>
                                                </div>
                                            </div>
                                            {isError && (
                                                <div className="w-100 text-danger text-center mt-2" style={{ fontSize: '0.875rem' }}>
                                                    Quantity exceeds allowed limit: {maxQuantity}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="cart-table-wrapper table-responsive">
                            <h4 className="h4">Save For Later</h4>
                            <table className="table table-responsive-sm table-cart-product shopping-cart">
                                <thead>
                                    <tr className="cart-product-desc-list">
                                        <th className="ps-0" width="290px">
                                            <div className="h6 mb-0 text-start">Product</div>
                                        </th>
                                        <th>
                                            <div className="h6 mb-0">Price</div>
                                        </th>
                                        <th>
                                            <div className="h6 mb-0">Tax(%)</div>
                                        </th>
                                        <th>
                                            <div className="h6 mb-0">Quantity</div>
                                        </th>
                                        <th>
                                            <div className="h6 mb-0">Subtotal</div>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="cart-product-desc-list">
                                        <td className="option text-start d-flex flex-row align-items-center ps-0"
                                            title="Personalized Classic Travel Necessities Hamper">
                                            <figure className="rounded cart-img">
                                                <a
                                                    >
                                                    <img src="https://shop.giivv.com/uploads/media/2024/p-personalized-classic-travel-necessities-hamper-322255-m.png"
                                                        alt="Personalized Classic Travel Necessities Hamper" 
                                                        className="image-fit rounded" 
                                                        style={{ width: '5rem' }}
                                                    />
                                                </a>
                                            </figure>
                                            
                                            <div className="w-100 ms-4">
                                                <h3 className="post-title h6 lh-xs mb-1"
                                                    title="Personalized Classic Travel Necessities Hamper">
                                                    <a className="text-decoration-none text-dark"
                                                        
                                                        target="_blank">
                                                        Personalized Classic Travel Necessities Hamper </a>
                                                </h3>
                                            </div>

                                        </td>
                                        <td>
                                            <p className="price"><span className="amount">₹1,400.00</span></p>
                                        </td>
                                        <td> - </td>
                                        <td className="item-quantity">
                                            <div className="num-block skin-2 product-quantity" style={{ width: '5.6rem' }}>
                                                <div className="d-flex align-items-center border-secondary">
                                                    <button
                                                        className="btn btn-outline-secondary decrement-btn border-0 p-1 py-1 fs-4"
                                                        // onClick={() => handleCartDecrement(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <input
                                                        type="text"
                                                        className="form-control text-center mx-1 quantity-input border-0"
                                                        style={{ width: "40px" }}
                                                        // value={quantity}
                                                        readOnly
                                                    />
                                                    <button
                                                        className="btn btn-outline-secondary increment-btn border-0 p-1 py-1 fs-4"
                                                        // onClick={() => handleCartIncrement(index)}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center p-0 total-price"><span className="product-line-price"> ₹1,400.00</span></td>
                                        <td className="d-flex gap-2 align-items-center border-0">
                                            <a className="product-removal link_cursor">
                                                <i className="remove-product uil uil-trash-alt fs-23 text-danger" name="remove_inventory"
                                                    id="remove_inventory" data-id="19" title="Remove From Cart"></i>
                                            </a>
                                            <a className="save-for-later remove-product link_cursor" data-id="19">
                                                <i className="uil uil-bag-alt fs-23 text-blue" title="Save For Later"></i>
                                            </a>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-10">
                        <h3 className="mb-4">Cart total</h3>
                        <div className="table-responsive">
                            <table className="table table-order">
                                <tbody>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">Subtotal</strong></td>
                                        <td className="pe-0 text-end product-card-content">
                                            <p className="price">₹ {cartTotalAmount.toLocaleString()}0</p>
                                        </td>
                                    </tr>

                                    <tr className="total-price">
                                        <td className="ps-0"><strong className="text-dark">Total</strong></td>
                                        <td className="pe-0 text-end product-card-content">
                                            <p className="price text-dark fw-bold">₹<span id="final_total"> {cartTotalAmount.toLocaleString()}</span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="checkout-method mb-3">
                            {/* <Link to="/billing-details"> */}
                                <button className="btn bg-secondary text-white fw-bold rounded-pill w-100 mt-4" onClick={handleCheckout}>
                                    Go To Checkout 
                                </button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </Base>
    )

}

// ViewCart.propTypes = {
//     qty: PropTypes.string.isRequired,
//     productVariantId: PropTypes.string.isRequired,
// };
